import React, { useState, useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Check } from '@mui/icons-material'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material'

import { useAppContext } from '../../../components/AppContext'
import Page from '../../../components/Page'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import DataTable from '../../../components/DataTable'
import { ModalExportData } from '../../../components/modals/ModalExportData'
import WorkflowExpandedRow from './WorkflowExpandedRow'

import { navigationLinking } from '../../../services/navigation'
import { eventTypes } from '../../../services/constants'
import queryKeys from '../../../services/queryKeys'
import {
	getForms,
	deleteForm,
	undeleteForm,
	getForm,
	saveForm,
} from '../../../services/client'
import {
	borrowerTypes,
	formatDate,
	formTypes,
	getErrorMessage,
} from '../../../services/helper'
import {
	exportToCsv,
	exportToExcel,
	exportToJSON,
	rowActions,
} from '../../../services/utils'

import { useMixpanel } from '../../../hooks/useMixpanel'
import { useAlert, useWindowSize } from '../../../hooks'
import { getTheme } from '../../../config'

const theme = getTheme()

export default function AdminWorkflows() {
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const navigate = useNavigate()
	const mixpanel = useMixpanel()
	const queryClient = useQueryClient()
	const { state } = useAppContext()
	const { user } = state

	const [filteredData, setFilteredData] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [rowToRestore, setRowToRestore] = useState()
	const [rowToExport, setRowToExport] = useState()
	const [exportModalVisible, setExportModalVisible] = useState(false)
	const [searchText, setSearchText] = useState('')
	const [rowToFetchWorkflow, setRowToFetchWorkflow] = useState({
		id: null,
		action: '',
	})
	const [filters, setFilters] = useState({
		type: 'all',
		borrowerType: 'all',
		active: 'active',
		hideDefaults: false,
	})

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Borrower Type',
			selector: (row) => row.borrowerType,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Description',
			selector: (row) => row.description,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Default',
			selector: (row) => (row.isDefault ? <Check /> : '-'),
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
		{
			name: 'Duplicate Workflow',
			onClick: (e, row) => duplicateRow(row),
		},
		{
			name: 'Export',
			onClick: (e, row) => showExportModal(row),
		},
		{
			name: 'Delete',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null || row.isDefault,
		},
		{
			name: 'Restore',
			onClick: (e, row) => showRestoreModal(row),
			hideIf: (row) => row.deletedAt === null,
		},
	]

	const {
		isFetching,
		isRefetching,
		error: errorFetchingWorkflows,
		data: workflowsResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.workflows],
		queryFn: () => getForms(true),
		select: (data) => {
			const overrides = data
				.filter((row) => !row.isDefault)
				.map((row) => row.name)

			return data
				.filter(
					(row) => !row.isDefault || !overrides.includes(row.name)
				)
				.map((row) => ({
					...row,
					borrowerType: borrowerTypes[row.borrowerType],
					type: formTypes[row.type] || '-',
				}))
		},
	})

	const {
		data: workflowData,
		error: errorFetchingWorkflow,
		isSuccess: isWorkflowFetched,
	} = useQuery({
		queryKey: [queryKeys.workflow],
		queryFn: () => getForm(rowToFetchWorkflow?.id),
		enabled: !!rowToFetchWorkflow?.id,
	})

	useEffect(() => {
		if (errorFetchingWorkflows) {
			alert(getErrorMessage(errorFetchingWorkflows), {
				severity: 'error',
			})
		} else if (errorFetchingWorkflow) {
			alert(getErrorMessage(errorFetchingWorkflow), {
				severity: 'error',
			})
		}
	}, [errorFetchingWorkflows, errorFetchingWorkflow])

	useEffect(() => {
		if (
			searchText !== '' ||
			filters.type !== 'all' ||
			filters.borrowerType !== 'all' ||
			filters.active !== 'all' ||
			filters.hideDefaults
		) {
			let newFilteredData = !searchText
				? workflowsResult
				: workflowsResult.filter(
						(d) =>
							d.name
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.description
								?.toLowerCase()
								.includes(searchText.toLowerCase())
					)
			newFilteredData = newFilteredData?.filter((d) => {
				if (filters.type !== 'all' && d.type !== filters.type)
					return false
				if (
					filters.borrowerType !== 'all' &&
					d.borrowerType !== filters.borrowerType
				)
					return false
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return !(filters.hideDefaults && d.isDefault)
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(workflowsResult)
		}
	}, [workflowsResult, searchText, filters])

	useEffect(() => {
		if (
			isWorkflowFetched &&
			rowToFetchWorkflow?.action === rowActions.export
		)
			setRowToExport(workflowData)
		if (
			isWorkflowFetched &&
			rowToFetchWorkflow?.action === rowActions.duplicate
		)
			handleCreateDuplication()
	}, [isWorkflowFetched, rowToFetchWorkflow])

	const createFormMutation = useMutation({
		mutationFn: (data) => saveForm(data),
		onSuccess: async (result) => {
			await queryClient.invalidateQueries([queryKeys.workflows])
			setRowToFetchWorkflow({ id: null, action: '' })
			alert(`Workflow ${result.name} was created`)
		},
	})

	const removeFormMutation = useMutation({
		mutationFn: (id) => deleteForm(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				queryKeys.workflows,
				queryKeys.workflow,
			])

			const { id, name } = rowToRemove
			mixpanel.trackEvent(eventTypes.WORKFLOW_DELETED, {
				name,
				id,
			})
			setRemoveModalVisible(false)
			alert(`Workflow "${name}" successfully removed`)
			setRowToRemove(null)
		},
	})

	const restoreFormMutation = useMutation({
		mutationFn: (id) => undeleteForm(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				queryKeys.workflows,
				queryKeys.workflow,
			])

			const { id, name } = rowToRestore
			mixpanel.trackEvent(eventTypes.WORKFLOW_RESTORED, {
				name,
				id,
			})
			setRestoreModalVisible(false)
			alert(`Workflow "${name}" successfully restored`)
			setRowToRestore(null)
		},
	})

	const removeForm = async () => {
		try {
			if (rowToRemove && rowToRemove?.id)
				await removeFormMutation.mutateAsync(rowToRemove?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const restoreForm = async () => {
		try {
			if (rowToRestore && rowToRestore?.id)
				await restoreFormMutation.mutateAsync(rowToRestore?.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleCreateDuplication = async () => {
		try {
			if (workflowData) {
				delete workflowData.id
				workflowData.isDefault = false
				workflowData.accountID = user.accountID
				workflowData.name = `${workflowData.name} Copy`
				await createFormMutation.mutateAsync(workflowData)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const duplicateRow = (row) => {
		setRowToFetchWorkflow({
			id: row?.id,
			action: rowActions.duplicate,
		})
	}

	const showExportModal = (row) => {
		setRowToFetchWorkflow({ id: row?.id, action: rowActions.export })
		setExportModalVisible(true)
	}

	const exportRow = (fileType) => {
		const data = rowToExport.formJSON
		switch (fileType) {
			case 'csv':
				exportToCsv(data, rowToExport.name, false)
				break
			case 'xlsx':
				exportToExcel(data, rowToExport.name, false)
				break
			case 'json':
				exportToJSON(data, rowToExport.name)
				break
			default:
				break
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setRowToRestore(row)
	}

	const onRowClicked = async (row) => {
		navigate(
			generatePath(`/${navigationLinking.AdminWorkflowEdit}`, {
				id: row.id,
			})
		)
	}

	const onAddClick = async () => {
		navigate(
			generatePath(`/${navigationLinking.AdminWorkflowEdit}`, {
				id: 'new',
			})
		)
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const handleCheckBoxChange = (field) => {
		setFilters((f) => ({ ...f, [field]: !f[field] }))
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page title="Workflows" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeForm}
					row={rowToRemove}
					loading={removeFormMutation.isPending}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreForm}
					row={rowToRestore}
					loading={restoreFormMutation.isPending}
				/>
				<ModalExportData
					visible={exportModalVisible}
					setVisible={setExportModalVisible}
					action={exportRow}
				/>
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title="Workflows"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					searchFilters={
						<>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Form Type</InputLabel>
								<Select
									value={filters.type}
									onChange={(e) => handleFilterChange(e, 'type')}
								>
									<MenuItem value="all">All</MenuItem>
									{formTypes.map((type, index) => (
										<MenuItem key={type} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl id="filterBorrowerType" variant="standard">
								<InputLabel>Borrower Role</InputLabel>
								<Select
									value={filters.borrowerType}
									onChange={(e) =>
										handleFilterChange(e, 'borrowerType')
									}
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="Borrower">Borrower</MenuItem>
									<MenuItem value="CoBorrower">CoBorrower</MenuItem>
									<MenuItem value="Non-Borrowing Spouse">
										Non-Borrowing Spouse
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
							<FormControlLabel
								label={
									<Typography className="dark:text-white">
										Hide Defaults
									</Typography>
								}
								className="select-none"
								control={
									<Checkbox
										id={`hideDefaults`}
										checked={filters.hideDefaults}
										onChange={(e) =>
											handleCheckBoxChange('hideDefaults')
										}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
							/>
						</>
					}
					exportEnabled={true}
					fixedHeader={true}
					expandableRowsComponent={WorkflowExpandedRow}
					actionItems={actionItems}
					addNewBtn={{ text: 'Add Workflow', onClick: onAddClick }}
				/>
			</div>
		</Page>
	)
}
