import React, { useEffect, useMemo, useState } from 'react'
import {
	generatePath,
	useParams,
	useNavigate,
} from 'react-router-dom'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import SwipeableViews from 'react-swipeable-views'
import { cloneDeep } from 'lodash'
import { useTheme } from '@mui/material/styles'
import {
	Button,
	Card,
	CardContent,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Select,
	Switch,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material'
import AddCommentIcon from '@mui/icons-material/AddComment'

import Page from '../../../components/Page'
import { LoadingBtn } from '../../../components/Button'
import { ModalWorkflowQuestion } from '../../../components/modals/ModalWorkflowQuestion'
import TabPanel from '../../../components/TabPanel'
import MailMergeSelect from '../../../components/MailMergeSelect'
import { useAppContext } from '../../../components/AppContext'
import CodeEditor from '../../../components/CodeEditor'
import ModalVersionList from '../../../components/modals/ModalVersionList'
import ActiveVersionAlert from '../../../components/versions/ActiveVersionAlert'
import VersionSelector from '../../../components/versions/VersionSelector'
import ModalNewVersion from '../../../components/modals/ModalNewVersion'

import Prequal from '../../../workflows/borrower-prequal.json'
import SuperShort from '../../../workflows/super-short-app.json'
import Borrower from '../../../workflows/borrower-app.json'
import CoBorrower from '../../../workflows/coborrower-app.json'
import LOBorrower from '../../../workflows/lo-borrower-app.json'
import LOCoBorrower from '../../../workflows/lo-coborrower-app.json'
import Test from '../../../workflows/test-app.json'
import SpanishLOBorrower from '../../../workflows/lo-borrower-app-spanish.json'
import SpanishBorrower from '../../../workflows/borrower-app-spanish.json'
import SpanishPrequal from '../../../workflows/borrower-prequal-spanish.json'
import LoanApp from '../../loanapp/LoanApp'

import {
	deleteFormVersion,
	getForm,
	saveForm,
	saveFormVersion,
} from '../../../services/client'
import {
	a11yProps,
	findWorkflowProperties,
	formTypes,
	getErrorMessage,
	questionTypes,
} from '../../../services/helper'
import {
	getExampleQuestionByType,
	isJsonString,
} from '../../../services/utils'
import { fillRecursiveQuestions } from '../../../services/appService'
import { eventTypes } from '../../../services/constants'
import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'

import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'

const Initial = [
	{
		title: 'The Big POS Workflow Editor',
		heading: 'Welcome to the Future of Lending Applications',
		question:
			"With The Big Point of Sale's Workflow Editor, you can create and customize workflows to meet your specific lending needs. Use the panel on the left to add question, the middle section will display the source code that drives the application, and this area will show you a preview of the workflow as you build it. If you need help, contact our support team and we'll be happy to assist you.",
		type: 'information',
		buttonText: 'OK I Got It',
		logo: true,
		qId: 'WelcomeStatement',
		fieldId: 'WelcomeStatement',
		losFieldId: '',
		name: 'Welcome Statement',
		target: '',
	},
]

const defaultApps = {
	Initial,
	Prequal,
	'Super Short': SuperShort,
	Borrower,
	CoBorrower,
	'Spanish Borrower': SpanishBorrower,
	'Spanish Prequal': SpanishPrequal,
	'LO Borrower': LOBorrower,
	'LO CoBorrower': LOCoBorrower,
	'LO Spanish Borrower': SpanishLOBorrower,
	Test,
}

export default function WorkflowEdit() {
	const { id } = useParams()
	const { alert } = useAlert()
	const queryClient = useQueryClient()
	const mixpanel = useMixpanel()
	const { state } = useAppContext()
	const { user } = state
	const themeMUI = useTheme()
	const navigate = useNavigate()

	const [workflow, setWorkflow] = useState({
		id: '',
		name: '',
		formJSON: '[]',
		type: 0,
		borrowerType: 0,
		isDefault: false,
		target: 'Lead',
		authType: '',
	})
	const [activeQuestion, setActiveQuestion] = useState('')
	const [questionModalOpen, setQuestionModalOpen] = useState(false)
	const [tab, setTab] = useState(0)
	const [targets, setTargets] = useState([])
	const [invalidTargets, setInvalidTargets] = useState([])
	const [anchorEl, setAnchorEl] = React.useState()
	const open = Boolean(anchorEl)
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [versionsModalOpen, setVersionsModalOpen] = useState(false)
	const [selectedVersionActive, setSelectedVersionActive] =
		useState(false)
	const [newVersionModalOpen, setNewVersionModalOpen] =
		useState(false)

	const {
		isFetching,
		data: workflowResult,
		error: errorFetchingWorkflow,
	} = useQuery({
		queryKey: [queryKeys.workflow],
		queryFn: () => getForm(id),
		enabled: id && id !== 'new',
	})

	const currentActiveVersion = useMemo(() => {
		return workflow?.versions?.find((v) => v.isActive)
	}, [workflow])

	useEffect(() => {
		if (errorFetchingWorkflow) {
			alert(getErrorMessage(errorFetchingWorkflow), {
				severity: 'error',
			})
		}
	}, [errorFetchingWorkflow])

	useEffect(() => {
		if (id && id !== 'new') {
			if (workflowResult) {
				setWorkflow({
					...workflowResult,
					formJSON: JSON.stringify(
						workflowResult?.formJSON,
						undefined,
						4
					),
				})
				const activeVersion = workflowResult.versions.find(
					(v) => v.isActive
				)
				setSelectedVersion(activeVersion)
			}
		} else
			setWorkflow((current) => ({
				...current,
				name: 'New Workflow',
				formJSON: JSON.stringify(
					defaultApps['Initial'],
					undefined,
					4
				),
			}))
	}, [id, isFetching])

	useEffect(() => {
		if (workflow?.formJSON && isJsonString(workflow.formJSON)) {
			setTargets(
				JSON.parse(workflow.formJSON)
					?.map((q) => q.qId)
					.filter((t) => !!t)
					.sort()
			)
		}
	}, [workflow])

	useEffect(() => {
		if (isJsonString(workflow.formJSON)) checkTargets()
	}, [workflow.formJSON])

	const createFormMutation = useMutation({
		mutationFn: (data) => saveForm(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([queryKeys.workflows])
		},
	})

	const createVersionMutation = useMutation({
		mutationFn: (data) => saveFormVersion(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				queryKeys.workflows,
				queryKeys.workflow,
			])
		},
	})

	const deleteVersionMutation = useMutation({
		mutationFn: (data) => deleteFormVersion(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				queryKeys.workflows,
				queryKeys.workflow,
			])
		},
	})

	const canSubmit =
		workflow?.name &&
		workflow?.formJSON &&
		workflow?.authType &&
		isJsonString(workflow.formJSON) &&
		invalidTargets.length === 0

	const updateJSONText = (value) => {
		setWorkflow((current) => ({ ...current, formJSON: value }))
	}

	// useEffect(() => {
	// 	if (isJsonString(workflow.formJSON)) checkTargets()
	// }, [workflow.formJSON])

	// const appendItem = type => {
	// 	const newQuestion = getExampleQuestionByType(type)
	// 	newQuestion.qId = `${newQuestion.type}${JSON.parse(workflow.formJSON).length + 1}`
	// 	newQuestion.fieldId = newQuestion.qId
	// 	setWorkflow(current => {
	// 		const override = JSON.parse(current.formJSON)
	// 		if (override.length) {
	// 			const lastQuestion = override.pop()
	// 			lastQuestion.target = newQuestion.qId
	// 			override.push(lastQuestion)
	// 		}
	// 		current.formJSON = JSON.stringify([...override, newQuestion], undefined, 4)
	// 		return current
	// 	})
	// }

	// const reset = () => {
	// 	setWorkflow(current => ({ ...current, formJSON: JSON.stringify(defaultApps[importFileName || 'Initial'], undefined, 4) }))
	// }

	// const clear = () => {
	// 	setWorkflow(current => ({ ...current, formJSON: JSON.stringify([], undefined, 4) }))
	// 	setImportFileName('')
	// }

	const handleNewVersionDialogClose = () => {
		setNewVersionModalOpen(false)
	}

	const handleNewVersionClick = () => {
		setNewVersionModalOpen(true)
	}

	const handleViewVersionsClick = () => {
		setVersionsModalOpen(true)
	}

	const handleViewVersionsClose = () => {
		setVersionsModalOpen(false)
	}

	const handleVersionChange = (e) => {
		const version = workflow.versions.find(
			(v) => v.id === e.target.value
		)
		setSelectedVersion(version)
		setSelectedVersionActive(false)
		setWorkflow((current) => ({
			...current,
			formJSON: JSON.stringify(version?.formJSON, undefined, 4),
		}))
	}

	const handleVersionClick = (version) => {
		setSelectedVersion(version)
		setVersionsModalOpen(false)
	}

	const handleVersionDelete = async (version) => {
		try {
			await deleteVersionMutation.mutateAsync({
				formId: workflow.id,
				versionId: version.id,
			})
			setVersionsModalOpen(false)
			const updatedWorkflow = cloneDeep(workflow)
			updatedWorkflow.versions = updatedWorkflow.versions.filter(
				(v) => v.id !== version.id
			)
			setWorkflow(updatedWorkflow)
			alert(`Version ${version.name} successfully deleted`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const checkTargets = () => {
		const questions = fillRecursiveQuestions(
			JSON.parse(workflow.formJSON)
		)

		const attemptedTargets = findWorkflowProperties({
			questions,
			prop: 'target',
			searchOptions: true,
		})
		const validTargets = findWorkflowProperties({
			questions,
			prop: 'qId',
			searchGroups: false,
		})
		let invalid = []
		attemptedTargets.forEach((target) => {
			if (!validTargets.includes(target)) invalid.push(target)
		})
		invalid = invalid.filter(
			(target) => !target.includes('***') && !target.includes('---')
		)

		setInvalidTargets(invalid)
	}

	const handleNewFormSubmit = async (data) => {
		try {
			const version = await createVersionMutation.mutateAsync({
				formId: workflow?.id,
				data: {
					formJSON: JSON.parse(workflow?.formJSON),
					...data,
				},
			})

			const updatedWorkflow = cloneDeep(workflow)
			if (data.isActive) {
				const prevActiveVersionIndex =
					updatedWorkflow.versions.findIndex((v) => v.isActive)
				updatedWorkflow.versions[prevActiveVersionIndex].isActive =
					false
			}
			updatedWorkflow.versions.unshift(version)
			setWorkflow(updatedWorkflow)
			setSelectedVersion(version)
			setSelectedVersionActive(false)
			setNewVersionModalOpen(false)
			alert(`Version ${version.name} successfully created`)
		} catch (e) {
			setNewVersionModalOpen(false)
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const save = async () => {
		try {
			const { id, name } = workflow

			if (workflow.isDefault) {
				delete workflow.id
				delete workflow.isDefault
				workflow.accountID = user.accountID
			}
			const result = await createFormMutation.mutateAsync({
				...workflow,
				formJSON: JSON.parse(workflow.formJSON),
			})

			const eventType = workflow.id
				? eventTypes.WORKFLOW_UPDATED
				: eventTypes.WORKFLOW_CREATED
			mixpanel.trackEvent(eventType, {
				name,
				...(id && { id }),
			})
			alert(
				`Workflow ${workflow.name} successfully ${workflow.id ? 'updated' : 'created'}`
			)
			if (result.id !== workflow.id) {
				// if this is a new template or default override, redirect to the appropriate page
				navigate(
					generatePath(`/${navigationLinking.AdminWorkflowEdit}`, {
						id: result.id,
					})
				)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}
	const showQuestionOptions = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const hideQuestionOptions = () => {
		setAnchorEl(null)
	}

	const handleTabChange = (event, value) => {
		if (value === 1) {
			mixpanel.trackEvent(eventTypes.WORKFLOW_PREVIEW)
		}
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const updateWorkflow = (key, value) => {
		setWorkflow((current) => ({ ...current, [key]: value }))
	}

	const updateQuestion = (question) => {
		const newJSON = JSON.parse(workflow.formJSON).map((q) =>
			q.qId !== question.qId ? q : question
		)
		updateWorkflow('formJSON', JSON.stringify(newJSON, undefined, 4))
	}

	const handleAddQuestion = (type) => {
		setActiveQuestion(
			JSON.stringify(getExampleQuestionByType(type), undefined, 4)
		)
		setQuestionModalOpen(true)
	}

	const editQuestion = (question) => {
		setActiveQuestion(JSON.stringify(question, undefined, 4))
		setQuestionModalOpen(true)
	}

	return (
		<Page isFullWidth={true} title="Edit Workflow">
			<ModalNewVersion
				open={newVersionModalOpen}
				onDialogClose={handleNewVersionDialogClose}
				onSubmit={handleNewFormSubmit}
				loading={createVersionMutation.isPending}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<div className={`flex justify-between`}>
					<div>
						<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
							Workflows -{' '}
							{id === 'new' ? 'Create' : `${workflow.name}`}
						</p>
					</div>
					<div className={`flex`}>
						{!workflow?.isDefault && id !== 'new' && (
							<div className="mr-3">
								<LoadingBtn
									className="dark:text-white"
									disabled={!canSubmit || 0 !== tab}
									text="Save as new version"
									loading={createVersionMutation.isPending}
									onClick={handleNewVersionClick}
									fullWidth={false}
									variant="outlined"
								/>
							</div>
						)}
						<LoadingBtn
							id={`AdminWorkflowEditSaveButton`}
							disabled={!canSubmit}
							loading={createFormMutation.isPending}
							text={`${workflow?.id ? 'Update Workflow' : 'Create Workflow'} ${workflow.type === -1 ? 'Block' : ''}`}
							onClick={save}
							fullWidth={false}
							style={{ height: 36 }}
						/>
					</div>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit Workflow" {...a11yProps(0)} />
					<Tab label="Preview" {...a11yProps(1)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<Card>
							<CardContent>
								<div>
									<MailMergeSelect combinedOptions />
								</div>
								<div className={`flex space-between`}>
									<div className={`w-1/5 pr-6`}>
										<Typography variant={'h6'}>
											Form Details
										</Typography>
										<FormControl sx={{ width: '100%', mt: 1 }}>
											<TextField
												className={`w-full`}
												value={workflow.name}
												onChange={(e) =>
													updateWorkflow('name', e.target.value)
												}
												label={`Name`}
												placeholder={'Name the workflow...'}
												variant="standard"
											/>
										</FormControl>
										<FormControl
											variant="standard"
											sx={{ width: '100%', mt: 3 }}
										>
											{!isNaN(workflow.type) && (
												<InputLabel>Application Type</InputLabel>
											)}
											<Select
												label={'Application Type'}
												className={`w-full`}
												value={workflow.type}
												onChange={(e) =>
													updateWorkflow('type', e.target.value)
												}
												variant="standard"
											>
												<MenuItem value={-1}>
													- Workflow Block -
												</MenuItem>
												{formTypes.map((name, index) => (
													<MenuItem key={index} value={index}>
														{name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<FormControl
											variant="standard"
											sx={{ width: '100%', mt: 3 }}
										>
											<InputLabel>Target</InputLabel>
											<Select
												label={'Target'}
												className={`w-full`}
												value={workflow.target}
												onChange={(e) =>
													updateWorkflow('target', e.target.value)
												}
												variant="standard"
												required
											>
												<MenuItem value="Lead">POS (Lead)</MenuItem>
												<MenuItem value="Loan">
													Encompass (Loan)
												</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											variant="standard"
											sx={{ width: '100%', mt: 3 }}
										>
											<InputLabel>Authorization Type</InputLabel>
											<Select
												label={'AuthType'}
												className={`w-full`}
												value={workflow.authType}
												onChange={(e) =>
													updateWorkflow('authType', e.target.value)
												}
												variant="standard"
												required
											>
												<MenuItem value="RequireAuth">
													Require Authorization
												</MenuItem>
												<MenuItem value="CreateUser">
													Create User & Load Portal
												</MenuItem>
												<MenuItem value="InviteUser">
													Invite User
												</MenuItem>
												<MenuItem value="None">None</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											variant="standard"
											sx={{ width: '100%', mt: 3 }}
										>
											{!isNaN(workflow.borrowerType) && (
												<InputLabel>Borrower Role</InputLabel>
											)}
											<Select
												label={'Borrower Role'}
												className={`w-full`}
												value={workflow.borrowerType}
												onChange={(e) =>
													updateWorkflow(
														'borrowerType',
														e.target.value
													)
												}
												variant="standard"
											>
												<MenuItem value={0}>Borrower</MenuItem>
												<MenuItem value={1}>Co-Borrower</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											variant="standard"
											sx={{ width: '100%', mt: 3 }}
										>
											<FormControlLabel
												control={
													<Switch
														onChange={(e) =>
															updateWorkflow(
																'showProgressBar',
																e.target.checked
															)
														}
														checked={workflow.showProgressBar}
													/>
												}
												label={'Progress Bar'}
											/>
										</FormControl>
										<FormControl sx={{ width: '100%', mt: 1 }}>
											<TextField
												className={`w-full`}
												value={workflow.description}
												onChange={(e) =>
													updateWorkflow(
														'description',
														e.target.value
													)
												}
												label={`Description`}
												placeholder={'Describe the workflow...'}
												variant="standard"
												multiline
												rows={5}
											/>
										</FormControl>

										{!workflow.isDefault &&
											workflow.versions?.length > 1 &&
											selectedVersion && (
												<div className="mt-3">
													<VersionSelector
														value={selectedVersion?.id}
														onChange={handleVersionChange}
														versions={workflow?.versions}
														fullWidth
													/>
												</div>
											)}

										{!selectedVersion?.isActive && (
											<FormControl className="flex items-start w-full">
												<FormControlLabel
													control={
														<Switch
															className="mr-2"
															checked={selectedVersionActive}
															onChange={() =>
																setSelectedVersionActive(
																	(current) => !current
																)
															}
															color="primary"
														/>
													}
													label="Activate"
													labelPlacement="start"
												/>
											</FormControl>
										)}
									</div>
									<div className={`w-4/5`}>
										{!workflow.isDefault &&
											workflow.versions?.length > 1 &&
											selectedVersion && (
												<>
													<div className="mb-3 mt-3 md:ml-3 md:flex md:flex-row md:items-center md:justify-end">
														<Button
															variant="outlined"
															color="primary"
															onClick={handleViewVersionsClick}
														>
															View Versions
														</Button>
													</div>
													{currentActiveVersion && (
														<ActiveVersionAlert
															status={
																currentActiveVersion?.id ===
																selectedVersion?.id
																	? 'active'
																	: 'inactive'
															}
														/>
													)}
												</>
											)}

										<ModalVersionList
											open={versionsModalOpen}
											versions={workflow.versions}
											onItemClick={handleVersionClick}
											onDeleteClick={handleVersionDelete}
											onDialogClose={handleViewVersionsClose}
										/>
										{workflow?.formJSON?.length > 0 && (
											<>
												<div className="absolute top-30 pt-3 right-40 z-100">
													<Tooltip title={'Add a Question'}>
														<IconButton onClick={showQuestionOptions}>
															<AddCommentIcon fontSize={'large'} />
														</IconButton>
													</Tooltip>
												</div>
												<Menu
													anchorEl={anchorEl}
													id="question-menu"
													open={open}
													onClose={hideQuestionOptions}
													onClick={hideQuestionOptions}
													PaperProps={{
														elevation: 0,
														sx: {
															overflow: 'visible',
															filter:
																'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
															mt: 1.5,
															'& .MuiAvatar-root': {
																width: 32,
																height: 32,
																ml: -0.5,
																mr: 1,
															},
															'&::before': {
																content: '""',
																display: 'block',
																position: 'absolute',
																top: 0,
																right: 14,
																width: 10,
																height: 10,
																bgcolor: 'background.paper',
																transform:
																	'translateY(-50%) rotate(45deg)',
																zIndex: 0,
															},
														},
													}}
													transformOrigin={{
														horizontal: 'right',
														vertical: 'top',
													}}
													anchorOrigin={{
														horizontal: 'right',
														vertical: 'bottom',
													}}
												>
													{questionTypes.map((option) => (
														<MenuItem
															onClick={() =>
																handleAddQuestion(option.value)
															}
															key={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Menu>
												<CodeEditor
													className="mt-2"
													height="60vh"
													defaultLanguage="json"
													value={workflow.formJSON}
													onChange={updateJSONText}
													loading={isFetching}
													errors={invalidTargets.map(
														(target) =>
															`"${target}" is not a valid target`
													)}
												/>
											</>
										)}
									</div>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card>
							<CardContent>
								<div
									className={`w-full border scroll-y h-full`}
									style={{ height: 1000, overflowY: 'scroll' }}
								>
									<LoanApp
										questionsOverride={workflow.formJSON}
										editQuestion={editQuestion}
									/>
								</div>
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
			{!!activeQuestion && (
				<ModalWorkflowQuestion
					targets={targets}
					question={activeQuestion}
					visible={questionModalOpen}
					setQuestion={setActiveQuestion}
					setVisible={setQuestionModalOpen}
					updateActiveQuestion={updateQuestion}
				/>
			)}
		</Page>
	)
}
