import React, { useEffect, useState, useRef } from 'react'
import { useVenti } from 'venti'
import { generatePath, useNavigate } from 'react-router-dom'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import RDataTable from 'react-data-table-component'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import MuiTextField from '@mui/material/TextField'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { visuallyHidden } from '@mui/utils'
import CancelIcon from '@mui/icons-material/Cancel'
import { Loading } from './Loading'
import { Button } from './Button'
import { TableFilters } from './admin/TableFilters'
import { TableMobileSort } from './admin/TableMobileSort'
import { useWindowSize } from '../hooks'
import { ModalWizard } from './modals/ModalWizard'
import { ModalRawData } from './modals/ModalRawData'
import { ModalUser } from './modals/ModalUser'
import { getTheme } from '../config'
import {
	formatDate,
	getRoleName,
	getTaskCategoryName,
	getTaskType,
	isDarkMode,
} from '../services/helper'
import { getFilters } from '../services/utils'
import { Roles } from '../services/client'
import { navigationLinking } from '../services/navigation'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { ExportTable } from './export/ExportTable'
import { ModalChangePassword } from './modals/ModalChangePassword'
import moment from 'moment'
import Heading from './Heading'
import TextField from './formControls/TextField'
import clsx from 'clsx'
import { Check } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import {
	FirstPage,
	LastPage,
	ChevronLeft,
	ChevronRight,
} from '@mui/icons-material'
const theme = getTheme()

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		padding: 10,
		fontSize: 14,
		maxWidth: 40,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	[`&.${tableCellClasses.body}`]: {
		padding: 10,
		fontSize: 14,
		whiteSpace: 'nowrap',
		maxWidth: 40,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}))

export const DataTable = ({
	headers,
	fields,
	data = [],
	fetching,
	entity,
	title,
	remove,
	restore,
	tableType,
	get,
	set,
	save,
	questions = [],
	editPath,
	viewPath,
	hideNew,
	refresh,
	handleFilterByCorporations,
	handleFilterByBranches,
	handleFilterByRoles,
	handleFilterByStatus,
	handleFilterByPartners,
	handleOnUse,
	handleDuplicate,
	handleOnExport,
	user,
	buttonTitle,
	allowExport = false,
	sortByField = 'createdAt',
	sortDirection = 'desc',
}) => {
	const searchRef = useRef(null)
	const ventiState = useVenti()
	const navigate = useNavigate()
	const themeMUI = useTheme()
	const [width] = useWindowSize()
	const [sortAscending, setSortAscending] = useState(false)
	const [orderBy, setOrderBy] = useState('')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [anchorEl, setAnchorEl] = useState(null)
	const [filterDeleted, setFilterDeleted] = useState('')
	const [modalWizard, setModalWizard] = useState({
		visible: false,
		data: null,
		title: '',
	})
	const [modalChangePassword, setModalChangePassword] = useState({
		visible: false,
		data: null,
	})
	const [state, setState] = useState({
		page: 0,
		searchText: '',
		tableData: [],
		tableRowData: {},
	})

	const deletedFilter = (row) => {
		if (
			![
				'Corporate',
				'Branch',
				'LoanOfficer',
				'Partner',
				'User',
				'DocumentTemplate',
				'Rule',
			].includes(entity)
		)
			return true

		if (!filterDeleted) return !row.deletedAt
		if (filterDeleted === 'deleted') return !!row.deletedAt
		return true
	}

	const sortLoanApplication = (fieldName, sortAscending) => {
		let sortedData = state.tableData.sort((a, b) =>
			fieldName === 'applicationDate'
				? (
						sortAscending
							? moment(a[fieldName]) < moment(b[fieldName])
							: moment(b[fieldName]) < moment(a[fieldName])
					)
					? 1
					: -1
				: (
							sortAscending
								? a[fieldName] < b[fieldName]
								: b[fieldName] < a[fieldName]
					  )
					? 1
					: -1
		)
		setPage(0)
		setState({ ...state, tableData: sortedData })
	}

	const createSortHandler = (property, index) => (event) => {
		setSortAscending(!sortAscending)
		setOrderBy(property)

		let sortedData = state.tableData.sort((a, b) =>
			property.includes('At') || property.includes('Date')
				? (
						sortAscending
							? moment(a[fields[index]]) < moment(b[fields[index]])
							: moment(b[fields[index]]) < moment(a[fields[index]])
					)
					? 1
					: -1
				: (
							sortAscending
								? a[fields[index]] < b[fields[index]]
								: b[fields[index]] < a[fields[index]]
					  )
					? 1
					: -1
		)
		setPage(0)
		setState({ ...state, tableData: sortedData })
	}

	useEffect(() => {
		setState({
			...state,
			tableData: data.filter(deletedFilter).sort((a, b) => {
				if (sortByField) {
					if (
						sortByField.includes('At') ||
						sortByField.includes('Date')
					) {
						if (sortDirection === 'desc')
							return moment(a[sortByField]) < moment(b[sortByField])
								? 1
								: -1
						return moment(a[sortByField]) > moment(b[sortByField])
							? 1
							: -1
					}

					if (sortDirection === 'desc')
						return a[sortByField] < b[sortByField] ? 1 : -1
					return a[sortByField] > b[sortByField] ? 1 : -1
				}

				return 1
			}),
		})
	}, [data, fetching, filterDeleted])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleSearch = (searchText) => {
		if (!searchText && !searchRef?.current?.value) {
			setState({
				...state,
				tableData: data.filter(deletedFilter),
				searchText: '',
			})

			return
		}

		const filter = getFilters(
			entity,
			searchText || searchRef?.current?.value
		)
		const filteredData = data.filter(filter)

		setPage(0)
		setState({
			...state,
			tableData: filteredData.filter(deletedFilter),
			searchText: searchText || searchRef?.current?.value,
		})
	}

	const onAddNew = async () => {
		if (entity === 'LoanApplication') {
			navigate(`/${navigationLinking.Apply}`)
		} else if (tableType) {
			if (tableType === 'loanApplication')
				navigate(`/${navigationLinking.Apply}`)
			else setModalWizard({ ...modalWizard, visible: true })
		} else {
			ventiState.set(theme.storageKeys[`editing${entity}Id`], 'new')
			const navigatePath = generatePath(
				`/${navigationLinking[`Admin${entity}Edit`]}`,
				{
					id: 'new',
				}
			)
			navigate(navigatePath)
		}
	}

	const onEdit = async (row) => {
		if (tableType && questions.length) {
			const data = { ...row }
			if (entity === 'Rule') data.targetUserRole++
			else if (entity === 'Task') {
				data.targetUserRole++
				data.taskCategory++
			}
			setModalWizard({ visible: true, data, title: `Edit ${entity}` })
		} else {
			if (entity === 'Corporate') {
				await ventiState.set(
					theme.storageKeys.editingCorporateId,
					row.corporateId
				)
				await ventiState.set(
					theme.storageKeys.editingCorporateSiteConfigurationId,
					row.id || row.ID
				)
			} else if (entity === 'Branch') {
				await ventiState.set(
					theme.storageKeys.editingBranchId,
					row.branchId
				)
				await ventiState.set(
					theme.storageKeys.editingBranchSiteConfigurationId,
					row.id || row.ID
				)
			} else if (entity === 'LoanOfficer') {
				await ventiState.set(
					theme.storageKeys.editingLoanOfficerId,
					row.id || row.ID
				)
				await ventiState.set(
					theme.storageKeys.editingLoanOfficerSiteConfigurationId,
					row.siteConfigurationId
				)
			} else if (entity === 'Partner') {
				await ventiState.set(
					theme.storageKeys.editingPartnerId,
					row.id || row.ID
				)
				await ventiState.set(
					theme.storageKeys.editingPartnerSiteConfigurationId,
					row.siteConfigurationId
				)
			} else {
				await ventiState.set(
					theme.storageKeys[`editing${entity}Id`],
					row.id || row.ID
				)
			}
			const navigatePath = generatePath(
				`/${navigationLinking[`Admin${entity}Edit`]}`,
				{
					id: row.id,
				}
			)
			navigate(navigatePath)
		}
	}

	const onView = async (row) => {
		await ventiState.set(
			theme.storageKeys[`viewing${entity}Id`],
			row.id || row.loanID
		)
		navigate(`/${viewPath}` || `/${[`Admin${entity}`]}`)
	}

	const onUse = async (row) => {
		if (typeof handleOnUse === 'function') handleOnUse(row)
		else
			switch (entity) {
				case 'LoanApplication':
					await ventiState.unset(theme.storageKeys.loanData)
					await ventiState.unset(theme.storageKeys.loanTasks)
					await ventiState.unset(theme.storageKeys.loanDocs)
					await ventiState.set(theme.storageKeys.loanId, row.loanID)
					navigate(`/${navigationLinking.Portal}`)
					break
				default:
					return null
			}
	}

	const onRemove = (row) => {
		handleClose()
		remove && remove(row)
	}

	const onRestore = (row) => {
		handleClose()
		restore && restore(row)
	}

	const onDuplicate = (row) => {
		handleClose()
		handleDuplicate && handleDuplicate(row)
	}

	const onExport = (row) => {
		handleClose()
		handleOnExport && handleOnExport(row)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onRefresh = (e) => {
		setState({
			...state,
			page: 0,
			searchText: '',
		})
		refresh(e)
	}

	const [menu, setMenu] = useState([])

	const showThreeDotsMenu = (e, row, index) => {
		e.stopPropagation()
		let menuItems = []
		setAnchorEl(e.currentTarget)
		if (!!row.deletedAt) {
			if (
				[
					'Corporate',
					'Branch',
					'LoanOfficer',
					'Partner',
					'User',
					'Rule',
				].includes(entity)
			)
				menuItems = [
					{ name: 'Restore', action: () => onRestore(row) },
				]
		} else if ('Rule' === entity)
			menuItems = [
				{ name: 'View/Add Tasks', action: () => onView(row) },
				{
					name: 'Edit/Review Rule Conditions',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Remove Rule & Tasks',
					action: () => {
						onRemove(row)
						handleClose()
					},
				},
			]
		else if ('Task' === entity)
			menuItems = [
				{
					name: 'Edit Task',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Remove Task',
					action: () => {
						onRemove(row)
						handleClose()
					},
				},
			]
		else if ('LoanApplication' === entity)
			menuItems = [
				{
					name: [Roles.borrower, Roles.superAdmin].includes(user.role)
						? 'View Application'
						: [Roles.loanOfficer, Roles.branchManager].includes(
									user.role
							  )
							? 'Impersonate'
							: 'Open Loan File',
					action: () => onUse(row),
				},
			]
		else if ('User' === entity)
			menuItems = [
				{
					name: 'Edit Profile',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Change Password',
					action: () => {
						showChangePasswordModal(row)
						handleClose()
					},
				},
			]
		else if ('LoanOfficer' === entity)
			menuItems = [
				{
					name: 'Edit',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Remove',
					action: () => {
						onRemove(row)
						handleClose()
					},
				},
			]
		else if ('Lead' === entity) {
			const opts = [
				{
					name: 'Edit',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
			]
			if (
				[Roles.loanOfficer, Roles.branchManager].includes(
					user.role
				) &&
				row.Status === 'New'
			)
				opts.push({
					name: 'Create Loan',
					action: () => {
						onUse(row)
						handleClose()
					},
				})
			menuItems = opts
		} else if ('Workflow' === entity)
			menuItems = [
				{
					name: 'Edit Workflow',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Duplicate Workflow',
					action: () => {
						onDuplicate(row)
						handleClose()
					},
				},
				{
					name: 'Export',
					action: () => {
						onExport(row)
						handleClose()
					},
				},
			]
		else if ('NotificationTemplate' === entity)
			menuItems = [
				{
					name: 'Edit',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
			]
		else
			menuItems = [
				{
					name: 'Edit',
					action: () => {
						onEdit(row)
						handleClose()
					},
				},
				{
					name: 'Remove',
					action: () => {
						onRemove(row)
						handleClose()
					},
				},
			]
		setMenu(menuItems)
	}

	const handleRowPress = async (row) => {
		if (!!row.deletedAt) return
		if (entity === 'LoanApplication') await onUse(row)
		else if (entity === 'RequestQueue') await showRawDataModal(row)
		else if (viewPath) await onView(row)
		else await onEdit(row)
	}

	const showRawDataModal = (row) => {
		setState((current) => ({ ...current, tableRowData: row }))
	}

	const showChangePasswordModal = (data) => {
		setModalChangePassword({
			visible: true,
			data,
		})
	}

	return (
		<>
			{tableType === 'users' && (
				<ModalChangePassword
					modal={modalChangePassword.visible}
					setModal={setModalChangePassword}
					modalData={modalChangePassword.data}
					modalTitle={`Change Password`}
					user={user}
				/>
			)}

			{questions.length > 0 && tableType === 'users' && (
				<ModalUser
					modal={modalWizard.visible}
					setModal={setModalWizard}
					questions={questions}
					save={save}
					modalData={modalWizard.data}
					modalTitle={modalWizard.title}
				/>
			)}

			{questions.length > 0 && tableType !== 'users' && (
				<ModalWizard
					modal={modalWizard.visible}
					setModal={setModalWizard}
					// get={get}
					set={set}
					questions={questions}
					type={tableType}
					save={save}
					modalData={modalWizard.data}
					modalTitle={modalWizard.title}
				/>
			)}

			<ModalRawData
				data={state.tableRowData}
				visible={Object.entries(state.tableRowData).length !== 0}
				closeModal={() =>
					setState((current) => ({ ...current, tableRowData: {} }))
				}
			/>

			<div>
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
							{title}
						</p>
						<Tooltip title={`Refresh table data`}>
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="refresh"
								onClick={onRefresh}
								disabled={fetching}
								sx={bgColor}
							>
								<img
									className="w-14 mt-1"
									src={
										themeMUI.palette.mode === 'light'
											? RefreshPNG
											: RefreshDarkPNG
									}
									onMouseOver={(e) =>
										(e.currentTarget.src =
											themeMUI.palette.mode === 'light'
												? RefreshGIF
												: RefreshDarkGIF)
									}
									onMouseOut={(e) =>
										(e.currentTarget.src =
											themeMUI.palette.mode === 'light'
												? RefreshPNG
												: RefreshDarkPNG)
									}
									alt="refresh"
								/>
							</IconButton>
						</Tooltip>
					</div>
					<div>
						{allowExport && (
							<ExportTable data={state.tableData} title={title} />
						)}
						{!['LoanApplication', 'RequestQueue'].includes(entity) &&
						!hideNew ? (
							<Button
								id={`AdminCorporateEditSaveButton`}
								text={buttonTitle || 'Add'}
								onClick={onAddNew}
							/>
						) : null}
					</div>
				</div>

				{fetching && (
					<div
						className="flex justify-center dark:bg-[#121212]"
						style={{ height: `calc(100vh - 216px)` }}
					>
						{' '}
						<Loading size="small" />{' '}
					</div>
				)}

				{!fetching && (
					<div className="flex flex-col items-left md:flex-row md:justify-between">
						<MuiTextField
							sx={{ mb: 3, mr: 2, width: width > 768 ? 420 : 186 }}
							id="search-field"
							label="Search..."
							variant="standard"
							value={state.searchText}
							onChange={(e) => handleSearch(e.target.value)}
							inputRef={searchRef}
						/>

						<TableFilters
							entity={entity}
							handleFilterByCorporations={handleFilterByCorporations}
							handleFilterByBranches={handleFilterByBranches}
							handleFilterByRoles={handleFilterByRoles}
							handleFilterByStatus={handleFilterByStatus}
							handleFilterByPartners={handleFilterByPartners}
							filterDeleted={filterDeleted}
							setFilterDeleted={setFilterDeleted}
						/>

						{width < 768 && entity === 'LoanApplication' && (
							<TableMobileSort
								entity={entity}
								sortLoanApplication={sortLoanApplication}
							/>
						)}
					</div>
				)}

				{!fetching && !state.tableData.length && (
					<p className="text-2xl font-rubik font-bold pl-8 pt-8 dark:text-white">
						No records to display
					</p>
				)}

				{!fetching && state.tableData.length > 0 && (
					<>
						{width > 768 ? (
							<Box sx={{ width: '100%' }}>
								<Paper sx={{ mb: 2 }}>
									<TableContainer>
										<Table
											sx={{ minWidth: 280 }}
											aria-labelledby="sticky table"
											stickyHeader
										>
											<TableHead>
												<TableRow>
													{headers.map((headCell, index) => (
														<StyledTableCell
															key={headCell.id}
															align="left"
															sortDirection={
																orderBy === headCell.id &&
																sortAscending
																	? 'asc'
																	: false
															}
														>
															{/* {{headCell.label}} */}
															<TableSortLabel
																active={orderBy === headCell.id}
																direction={
																	orderBy === headCell.id &&
																	sortAscending
																		? 'asc'
																		: 'desc'
																}
																onClick={createSortHandler(
																	headCell.id,
																	index
																)}
															>
																<span className="font-bold">
																	{headCell.label}
																</span>
															</TableSortLabel>
															{orderBy === headCell.id ? (
																<Box
																	component="span"
																	sx={visuallyHidden}
																>
																	{!sortAscending
																		? 'sorted descending'
																		: 'sorted ascending'}
																</Box>
															) : null}
														</StyledTableCell>
													))}
													{![
														'LoanApplication',
														'NotificationTemplate',
														'Workflow',
													].includes(entity) && (
														<StyledTableCell>
															<span className="font-bold">
																Active / Deleted
															</span>
														</StyledTableCell>
													)}
													<StyledTableCell sx={{ width: '10px' }} />
												</TableRow>
											</TableHead>
											<TableBody>
												{state.tableData
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((row, index) => (
														<TableRow
															className={
																!row.deletedAt
																	? `cursor-pointer`
																	: `cursor-not-allowed`
															}
															hover
															tabIndex={-1}
															key={`${row.name}${index}`}
															onClick={() => handleRowPress(row)}
														>
															{(fields || headers).map(
																(field, index) => {
																	if (
																		field === 'domain' ||
																		field === 'url'
																	)
																		return (
																			<StyledTableCell
																				key={field + index}
																			>
																				<a
																					style={{
																						color:
																							theme.color.primary
																								.blue,
																					}}
																					href={`https://${row.url}`}
																					target="_blank"
																					rel="noreferrer"
																				>
																					{row.url}
																				</a>
																			</StyledTableCell>
																		)

																	let value
																	switch (field) {
																		case 'logo':
																			value = (
																				<img
																					className="w-36 h-12"
																					src={row.logoUrl}
																					alt="LogoURL"
																				/>
																			)
																			break
																		case 'icon':
																			value = (
																				<img
																					className="w-36 h-12"
																					src={row.iconUrl}
																					alt="IconUrl"
																				/>
																			)
																			break
																		case 'photo':
																			value = (
																				<img
																					className="w-36 h-12"
																					src={row.photoUrl}
																					alt="PhotoUrl"
																				/>
																			)
																			break
																		case 'role':
																			value = row.role
																			break
																		case 'targetUserRole':
																			value = getRoleName(
																				row.targetUserRole
																			)
																			break
																		case 'tasks':
																			value = row.tasks.length
																			break
																		case 'taskCategory':
																			value = getTaskCategoryName(
																				row.taskCategory
																			)
																			break
																		case 'taskType':
																			value = getTaskType(
																				row.taskType
																			)
																			break
																		case 'createdAt':
																			value = formatDate(row[field])
																			break
																		case 'CreatedAt':
																			value = formatDate(row[field])
																			break
																		case 'applicationDate':
																			value = formatDate(
																				row[field],
																				false
																			)
																			break
																		default:
																			if (field.slice(-2) === 'at')
																				value = formatDate(row[field])
																			else if (
																				field.slice(-4) === 'date'
																			)
																				value = formatDate(
																					row[field],
																					false
																				)
																			else value = row[field]
																	}

																	if (typeof value === 'boolean')
																		return (
																			<StyledTableCell
																				align="center"
																				key={index}
																			>
																				{value ? <Check /> : '-'}
																			</StyledTableCell>
																		)

																	return (
																		<StyledTableCell key={index}>
																			{value}
																		</StyledTableCell>
																	)
																}
															)}
															{![
																'LoanApplication',
																'NotificationTemplate',
																'Workflow',
															].includes(entity) && (
																<StyledTableCell>
																	{!row.deletedAt
																		? 'Active'
																		: 'Deleted'}
																</StyledTableCell>
															)}
															<StyledTableCell
																sx={{ width: '10px' }}
																align="right"
															>
																<IconButton
																	size="small"
																	edge="start"
																	color="inherit"
																	aria-label="refresh"
																	onClick={(e) =>
																		showThreeDotsMenu(e, row, index)
																	}
																	sx={bgColor}
																>
																	<MoreVertIcon />
																</IconButton>
															</StyledTableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 50, 100]}
										component="div"
										count={state.tableData.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										sx={{
											'.MuiTablePagination-actions': {
												marginLeft: width > 450 ? 3 : 0,
											},
											'.MuiToolbar-root.MuiTablePagination-toolbar': {
												paddingLeft: 0.5,
											},
											'.MuiInputBase-root': {
												marginLeft: width > 450 ? 1 : 0,
												marginRight: width > 450 ? 4 : 0,
												fontSize: width > 450 ? 14 : 12,
											},
											'.MuiTablePagination-selectLabel': {
												fontSize: width > 450 ? 14 : 12,
											},
											'.MuiTablePagination-displayedRows': {
												fontSize: width > 450 ? 14 : 12,
											},
										}}
									/>
									<Menu
										id="menu-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorEl)}
										className="mt-8 sm:mt-10"
										onClose={handleClose}
									>
										{menu.map((item, index) => (
											<MenuItem key={index} onClick={item.action}>
												{item.name}
											</MenuItem>
										))}
									</Menu>
								</Paper>
							</Box>
						) : (
							<>
								{state.tableData
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map((row, index) => (
										<div
											className="flex flex-row mb-4 py-4 w-full shadow shadow-gray-600/30 rounded-lg dark:shadow-gray-400/40"
											key={`${row.name}${index}`}
											style={{ position: 'relative' }}
										>
											<div
												className="flex flex-col"
												style={{ width: `calc(100% - 47px)` }}
											>
												{(fields || headers).map((field, index) => {
													if (field === 'domain' || field === 'url')
														return (
															<div className="pl-4" key={index}>
																<p className="font-rubik text-sm text-slate-400 font-bold mb-1">
																	{headers[index].label}
																</p>
																<p className="whitespace-nowrap font-rubik text-lg text-slate-800 font-bold mb-3 text-ellipsis overflow-hidden dark:text-white">
																	<a
																		style={{
																			color: theme.color.primary.blue,
																		}}
																		href={`https://${row.url}`}
																		target="_blank"
																		rel="noreferrer"
																	>
																		{row.url}
																	</a>
																</p>
															</div>
														)

													let value
													switch (field) {
														case 'logo':
															value = (
																<img
																	className="w-36 h-12"
																	src={row.logoUrl}
																	alt="LogoURL"
																/>
															)
															break
														case 'icon':
															value = (
																<img
																	className="w-36 h-12"
																	src={row.iconUrl}
																	alt="IconUrl"
																/>
															)
															break
														case 'photo':
															value = (
																<img
																	className="w-36 h-12"
																	src={row.photoUrl}
																	alt="PhotoUrl"
																/>
															)
															break
														case 'role':
															value = row.role
															break
														case 'targetUserRole':
															value = getRoleName(row.targetUserRole)
															break
														case 'tasks':
															value = row.tasks.length
															break
														case 'taskCategory':
															value = getTaskCategoryName(
																row.taskCategory
															)
															break
														case 'taskType':
															value = getTaskType(row.taskType)
															break
														// case "createdAt":
														// 	value = formatDate(row[field])
														// 	break
														// case "applicationDate":
														// 	value = formatDate(row[field], false)
														// 	break
														default:
															if (field.slice(-2) === 'at')
																value = formatDate(row[field])
															else if (field.slice(-4) === 'date')
																value = formatDate(row[field], false)
															else {
																value = row[field]
															}
													}

													return (
														<div className="pl-4" key={index}>
															<p className="font-rubik text-sm text-slate-400 font-bold mb-1">
																{headers[index].label}
															</p>
															<p className="whitespace-nowrap font-rubik text-lg text-slate-800 font-bold mb-3 text-ellipsis overflow-hidden dark:text-white">
																{value}
															</p>
														</div>
													)
												})}
											</div>
											<div
												className="flex items-center self-start"
												style={{
													position: 'absolute',
													top: 5,
													right: -16,
												}}
											>
												<div className="pr-4">
													{!row.deleted ? 'Active' : 'Deleted'}
												</div>
												<div className="pr-4">
													<IconButton
														size="small"
														edge="start"
														color="primary"
														aria-label="refresh"
														onClick={(e) =>
															showThreeDotsMenu(e, row, index)
														}
														sx={bgColor}
													>
														<MoreVertIcon />
													</IconButton>
												</div>
											</div>
										</div>
									))}
								<TablePagination
									rowsPerPageOptions={[10, 25, 50, 100]}
									component="div"
									count={state.tableData.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									sx={{
										'.MuiTablePagination-actions': {
											marginLeft: width > 450 ? 3 : 0,
										},
										'.MuiToolbar-root.MuiTablePagination-toolbar': {
											paddingLeft: 0.5,
										},
										'.MuiInputBase-root': {
											marginLeft: width > 450 ? 1 : 0,
											marginRight: width > 450 ? 4 : 0,
											fontSize: width > 450 ? 14 : 12,
										},
										'.MuiTablePagination-selectLabel': {
											fontSize: width > 450 ? 14 : 12,
										},
										'.MuiTablePagination-displayedRows': {
											fontSize: width > 450 ? 14 : 12,
										},
									}}
								/>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorEl)}
									className="mt-8 sm:mt-10"
									onClose={handleClose}
								>
									{menu.map((item, index) => (
										<MenuItem key={index} onClick={item.action}>
											{item.name}
										</MenuItem>
									))}
								</Menu>
							</>
						)}
					</>
				)}
			</div>
		</>
	)
}

const ReactDataTable = ({
	title,
	titleActions,
	onRefreshClick,
	progressComponent,
	progressPending,
	addNewBtn,
	exportEnabled = false,
	exportData,
	data,
	onSearchChange,
	searchText,
	onClearSearchClick,
	searchFilters,
	columns,
	actionItems,
	expandableRows,
	expandableRowsComponent,
	refreshing,
	...rest
}) => {
	const { dataTable: breakpoint } = theme.breakpoints
	const themeMUI = useTheme()
	const [width] = useWindowSize()
	const [actionAnchorEl, setActionAnchorEl] = useState(null)
	const [selectedActionItem, setSelectedActionItem] = useState(null)

	const showActionMenu = (e, row) => {
		e.stopPropagation()
		setActionAnchorEl(e.currentTarget)
		setSelectedActionItem(row)
	}

	const customStyles = {
		responsiveWrapper: {
			style: {
				maxHeight: 'fit-content',
			},
		},
		headCells: {
			style: {
				backgroundColor: isDarkMode()
					? theme.color.primary.black
					: theme.color.primary.white,
			},
		},
		progress: {
			style: {
				backgroundColor: isDarkMode() && theme.color.primary.black,
			},
		},
		noData: {
			style: {
				backgroundColor: isDarkMode() && theme.color.primary.black,
				color: isDarkMode() && theme.color.primary.white,
			},
		},
	}

	const getPaginationIcon = (IconComponent) => (
		<IconComponent
			style={{
				color: isDarkMode() && theme.color.primary.white,
			}}
		/>
	)

	const hideActionMenu = () => {
		setActionAnchorEl(null)
		// timeout is to prevent the hidden options from showing before the menu fades out
		setTimeout(() => setSelectedActionItem(null), 500)
	}

	const onActionItemClick = (e, item) => {
		item.onClick(e, selectedActionItem)
		hideActionMenu()
	}

	const expanderIconStyles = isDarkMode()
		? { color: 'white' }
		: undefined

	return (
		<Box
			sx={{ width: '100%' }}
			className={
				rest.onRowClicked ? 'rdt_TableRow_Clickable' : undefined
			}
		>
			<header>
				<div
					className={clsx(
						'flex items-center mb-2',
						(title || titleActions) && addNewBtn
							? 'justify-between'
							: undefined,
						!title && !titleActions ? 'justify-end' : undefined
					)}
				>
					<div className="flex flex-row items-center">
						{title && (
							<Heading
								button={
									typeof onRefreshClick === 'function' ? (
										<IconButton
											size="large"
											edge="start"
											color="inherit"
											aria-label="refresh"
											onClick={onRefreshClick}
											disabled={progressPending}
											className="ms-10"
											sx={bgColor}
										>
											<Tooltip title={`Refresh table data`}>
												<img
													className="w-14 mt-1"
													src={
														themeMUI.palette.mode === 'light'
															? RefreshPNG
															: RefreshDarkPNG
													}
													onMouseOver={(e) =>
														(e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshGIF
																: RefreshDarkGIF)
													}
													onMouseOut={(e) =>
														(e.currentTarget.src =
															themeMUI.palette.mode === 'light'
																? RefreshPNG
																: RefreshDarkPNG)
													}
													alt="refresh"
												/>
											</Tooltip>
										</IconButton>
									) : null
								}
							>
								{title}
							</Heading>
						)}
					</div>

					{titleActions && (
						<div className={title ? 'ms-2' : undefined}>
							{titleActions}
						</div>
					)}
					<div className="flex items-center">
						{exportEnabled && (
							<ExportTable data={exportData || data} title={title} />
						)}
						{addNewBtn && (
							<>
								{addNewBtn.onClick && (
									<Button
										text={addNewBtn.text || 'Add'}
										onClick={addNewBtn.onClick}
										disabled={addNewBtn.disabled}
									/>
								)}
								{!addNewBtn.onClick && addNewBtn}
							</>
						)}
					</div>
				</div>
			</header>
			{(onSearchChange || searchFilters) && (
				<div className="mb-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
					{onSearchChange && (
						<TextField
							label="Search..."
							onChange={onSearchChange}
							value={searchText}
							InputProps={
								searchText && onClearSearchClick
									? {
											endAdornment: (
												<div className="pr-2">
													<IconButton
														edge="end"
														onClick={onClearSearchClick}
														size="small"
													>
														<CancelIcon fontSize="small" />
													</IconButton>
												</div>
											),
										}
									: undefined
							}
						/>
					)}
					{searchFilters && searchFilters}
				</div>
			)}
			<Paper sx={{ pt: 2, mb: 2 }}>
				{refreshing && !progressPending && (
					<div className="fixed z-50 w-full top-1/2 left-1/2 flex">
						<div>
							<Loading size="small" />
						</div>
					</div>
				)}
				<RDataTable
					customStyles={customStyles}
					progressComponent={
						progressComponent || <Loading size="small" />
					}
					progressPending={progressPending}
					data={data}
					expandableRows={
						expandableRowsComponent && expandableRows === undefined
							? width < breakpoint
							: expandableRows
					}
					expandableRowsComponent={expandableRowsComponent}
					columns={
						actionItems
							? [
									...columns,
									{
										name: '',
										cell: (row, index) => (
											<IconButton
												size="small"
												edge="start"
												color="inherit"
												aria-label="refresh"
												onClick={(e) => showActionMenu(e, row, index)}
												sx={bgColor}
											>
												<MoreVertIcon />
											</IconButton>
										),
										width: '60px',
									},
								]
							: columns
					}
					expandableIcon={{
						expanded: <ArrowDownIcon style={expanderIconStyles} />,
						collapsed: <ArrowRightIcon style={expanderIconStyles} />,
					}}
					paginationIconFirstPage={getPaginationIcon(FirstPage)}
					paginationIconLastPage={getPaginationIcon(LastPage)}
					paginationIconPrevious={getPaginationIcon(ChevronLeft)}
					paginationIconNext={getPaginationIcon(ChevronRight)}
					{...rest}
				/>
			</Paper>
			{actionItems && (
				<Menu
					id="menu-appbar"
					anchorEl={actionAnchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(actionAnchorEl)}
					className="mt-8 sm:mt-10"
					onClose={hideActionMenu}
				>
					{actionItems.map((item, index) => {
						if (
							typeof item.hideIf === 'function' &&
							selectedActionItem &&
							item.hideIf(selectedActionItem)
						)
							return false
						return (
							<MenuItem
								key={index}
								onClick={(e) => onActionItemClick(e, item)}
							>
								{item.name}
							</MenuItem>
						)
					})}
				</Menu>
			)}
		</Box>
	)
}

export default ReactDataTable
