import React, { useState, useEffect, useMemo } from 'react'
import { useVenti } from 'venti'
import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import {
	getNotificationTemplates,
	deleteNotificationTemplate,
	undeleteNotificationTemplate,
} from '../../../services/client'
import {
	notificationTemplateTypes,
	formatDate,
	getErrorMessage,
} from '../../../services/helper'
import { getTheme } from '../../../config'
import { useAlert, useWindowSize } from '../../../hooks'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import { generatePath, useNavigate } from 'react-router-dom'
import { navigationLinking } from '../../../services/navigation'
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material'
import { Check } from '@mui/icons-material'

const theme = getTheme()

export default function AdminNotificationTemplates() {
	const [width] = useWindowSize()
	const ventiState = useVenti()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [fetching, setFetching] = useState(true)
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [activeRow, setActiveRow] = useState()
	const [filters, setFilters] = useState({
		type: 'all',
		borrowerType: 'all',
		active: 'active',
		hideDefaults: false,
	})
	const [searchText, setSearchText] = useState('')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.description,
			sortable: true,
		},
		{
			name: 'Subject',
			selector: (row) => row.subject,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			sortable: true,
		},
		{
			name: 'Send Email',
			selector: (row) => (row.emailEnabled ? <Check /> : '-'),
			sortable: true,
		},
		{
			name: 'Send Text',
			selector: (row) => (row.textEnabled ? <Check /> : '-'),
			sortable: true,
		},
		{
			name: 'Default',
			selector: (row) => (row.isDefault ? <Check /> : '-'),
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setActiveRow(row)
	}

	useEffect(() => {
		if (
			searchText !== '' ||
			filters.active !== 'all' ||
			filters.hideDefaults
		) {
			let newFilteredData = !searchText
				? data
				: data.filter(
						(d) =>
							d.name
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase()) ||
							d.description
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase()) ||
							d.subject
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase())
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return !(filters.hideDefaults && d.isDefault)
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(data)
		}
	}, [data, searchText, filters])

	const loadNotificationTemplates = async () => {
		try {
			setFetching(true)
			setData(await getNotificationTemplates(true))
			setFetching(false)
		} catch (e) {
			setFetching(false)
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const removeNotificationTemplate = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteNotificationTemplate(activeRow.id)
			await loadNotificationTemplates()
			alert(
				`Notification Template "${activeRow.name}" successfully removed`
			)
			setActiveRow(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setActiveRow(row)
	}

	const restoreNotificationTemplate = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteNotificationTemplate(activeRow.id)
			await loadNotificationTemplates()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	useEffect(() => {
		;(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadNotificationTemplates()
		})()
	}, [])

	const populatedTableData = useMemo(() => {
		return filteredData.map((row) => {
			const templateType = notificationTemplateTypes.find(
				(t) => t.value === row.type
			)
			return { ...row, type: templateType ? templateType.label : '' }
		})
	}, [filteredData])

	const onAddClick = () => {
		navigate(
			generatePath(
				`/${navigationLinking.AdminNotificationTemplateEdit}`,
				{ id: 'new' }
			)
		)
	}

	const onRowClicked = (row) => {
		navigate(
			generatePath(
				`/${navigationLinking.AdminNotificationTemplateEdit}`,
				{ id: row.id }
			)
		)
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	const handleCheckBoxChange = (field) => {
		setFilters((f) => ({ ...f, [field]: !f[field] }))
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	return (
		<Page
			page="app-notification-templates"
			title="Notification Templates"
			isFullWidth
		>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={removeNotificationTemplate}
				row={activeRow}
			/>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={restoreModalVisible}
				setRemoveModalVisible={setRestoreModalVisible}
				restore={restoreNotificationTemplate}
				row={activeRow}
			/>
			<div className="pl-5 pr-5 pb-10 pt-5 h-full overflow-auto">
				<DataTable
					data={populatedTableData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="name"
					pagination={true}
					progressPending={fetching}
					title="Notification Templates"
					onRefreshClick={loadNotificationTemplates}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchFilters={
						<>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
							<FormControlLabel
								label={
									<Typography className="dark:text-white">
										Hide Defaults
									</Typography>
								}
								className={`select-none`}
								control={
									<Checkbox
										id={`hideDefaults`}
										checked={filters.hideDefaults}
										onChange={(e) =>
											handleCheckBoxChange('hideDefaults')
										}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
							/>
						</>
					}
					exportEnabled={false}
					fixedHeader={true}
					actionItems={[
						{
							name: 'Edit',
							onClick: (e, row) => onRowClicked(row),
						},
						{
							name: 'Delete',
							onClick: (e, row) => showRemoveModal(row),
							hideIf: (row) =>
								row.deletedAt !== null || row.isDefault,
						},
						{
							name: 'Restore',
							onClick: (e, row) => showRestoreModal(row),
							hideIf: (row) => row.deletedAt === null,
						},
					]}
					addNewBtn={{
						text: 'Add Notification Template',
						onClick: onAddClick,
					}}
				/>
			</div>
		</Page>
	)
}
