import React, { useEffect, useState } from 'react'
import { filter } from 'lodash'
import { useVenti } from 'venti'
import PublishSubscribe from 'publish-subscribe-js'
import DataTable from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import Page from '../../../components/Page'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import { getTheme } from '../../../config'
import {
	getBranches,
	deleteBranch,
	undeleteBranch,
	getCorporations,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { useAlert, useWindowSize } from '../../../hooks'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { navigationLinking } from '../../../services/navigation'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'

const theme = getTheme()

export default function AdminBranches() {
	const [width] = useWindowSize()
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [dataCopy, setDataCopy] = useState([])
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [restoreModalVisible, setRestoreModalVisible] =
		useState(false)
	const [activeRow, setActiveRow] = useState()
	const [corporations, setCorporations] = useState([])
	const [filters, setFilters] = useState({
		type: 'all',
		brand: 'all',
		active: 'active',
	})

	const [searchText, setSearchText] = useState('')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'NMLSID',
			selector: (row) => row.nmlsid,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Brand',
			selector: (row) => row.corporateName,
			sortable: true,
		},
		{
			name: 'Domain',
			selector: (row) => row.url,
			sortable: true,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Active / Deleted',
			selector: (row) => (!row.deletedAt ? 'Active' : 'Deleted'),
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
	]

	useEffect(() => {
		;(async () => {
			if (!user.isLoggedIn) await logOut()
			else {
				await loadBranches()
				try {
					const corps = await getCorporations()
					setCorporations(
						corps.sort((a, b) =>
							a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
						)
					)
				} catch (e) {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				}
			}
		})()
	}, [user])

	useEffect(() => {
		PublishSubscribe.subscribe(
			'UPDATE_ADMIN_COMPONENT_STATE',
			async () => {
				await updateBranches()
			}
		)

		return () => {
			PublishSubscribe.unsubscribe('UPDATE_ADMIN_COMPONENT_STATE')
		}
	}, [])

	const loadBranches = async () => {
		try {
			setFetching(true)
			const branchesData = await getBranches(true)
			const branches = []
			branchesData.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					// id is being removed, so replaced with ID
					// will figure out later why
					branches.push({
						...site,
						ID: site.id,
						corporateID: item.corporateID,
						corporateName: item.corporateName,
						branchId: item.id,
						createdAt: item.createdAt,
						deletedAt: item.deletedAt,
						type: item.type,
					})
				})
			})
			setData(branches)
			setDataCopy(branches)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleFilterByCorporations = (value) => {
		if (!value) {
			setData(dataCopy)
		} else {
			setData(filter(dataCopy, { corporateID: value }))
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setActiveRow(row)
	}

	const updateBranches = async () => {
		const result = await getBranches()
		setData(result.rows)
	}

	const removeBranch = async () => {
		setRemoveModalVisible(false)

		try {
			const { branchId, name } = activeRow

			await deleteBranch(activeRow.branchId)
			await loadBranches()
			setActiveRow(null)
			mixpanel.trackEvent(eventTypes.BRANCH_DELETED, {
				name,
				id: branchId,
			})
			alert(`Branch "${activeRow.name}" successfully removed`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const showRestoreModal = (row) => {
		setRestoreModalVisible(true)
		setActiveRow(row)
	}

	const restoreBranch = async () => {
		setRestoreModalVisible(false)

		try {
			await undeleteBranch(activeRow.branchId)
			setData((data) => data.filter((d) => d.id !== activeRow.id))
			alert(`Branch ${activeRow.name} successfully restored`)
			setActiveRow(null)
			await loadBranches()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleFilterChange = (e, field) => {
		setFilters((f) => ({ ...f, [field]: e.target.value }))
	}

	useEffect(() => {
		if (
			searchText !== '' ||
			filters.type !== 'all' ||
			filters.brand !== 'all' ||
			filters.active !== 'all'
		) {
			let newFilteredData = !searchText
				? data
				: data.filter(
						(d) =>
							d.name
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.email
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.url
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.nmlsid?.toString().includes(searchText.toLowerCase())
					)
			newFilteredData = newFilteredData.filter((d) => {
				if (filters.type !== 'all' && d.type !== filters.type)
					return false
				if (
					filters.brand !== 'all' &&
					d.corporateID !== filters.brand
				)
					return false
				if (filters.active !== 'all') {
					if (filters.active === 'active' && d.deletedAt) return false
					else if (filters.active === 'deleted' && !d.deletedAt)
						return false
				}
				return true
			})
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(data)
		}
	}, [data, searchText, filters])

	const onRowClicked = async (row) => {
		await ventiState.set(
			theme.storageKeys.editingBranchId,
			row.branchId
		)
		await ventiState.set(
			theme.storageKeys.editingBranchSiteConfigurationId,
			row.id
		)
		navigate(`/${navigationLinking.AdminBranchEdit}`)
	}

	const onAddClick = async (row) => {
		await ventiState.set(theme.storageKeys.editingBranchId, 'new')
		navigate(`/${navigationLinking.AdminBranchEdit}`)
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	return (
		<Page isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeBranch}
					row={activeRow}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={restoreModalVisible}
					setRemoveModalVisible={setRestoreModalVisible}
					restore={restoreBranch}
					row={activeRow}
				/>
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="type"
					pagination={true}
					progressPending={fetching}
					title="Branches"
					onRefreshClick={loadBranches}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					searchFilters={
						<>
							<FormControl id="filterType" variant="standard">
								<InputLabel>Type</InputLabel>
								<Select
									value={filters.type}
									onChange={(e) => handleFilterChange(e, 'type')}
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="Mortgage">Mortgage</MenuItem>
									<MenuItem value="RealEstate">Real Estate</MenuItem>
								</Select>
							</FormControl>
							<FormControl id="filterBrand" variant="standard">
								<InputLabel>Brand</InputLabel>
								<Select
									value={filters.brand}
									onChange={(e) => handleFilterChange(e, 'brand')}
								>
									<MenuItem value="all">All</MenuItem>
									{corporations.map((corp, index) => (
										<MenuItem key={corp.id} value={corp.id}>
											{corp.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl id="filterActive" variant="standard">
								<InputLabel>Active / Deleted</InputLabel>
								<Select
									value={filters.active}
									onChange={(e) => handleFilterChange(e, 'active')}
								>
									<MenuItem value="active">Show Active Only</MenuItem>
									<MenuItem value="deleted">
										Show Deleted Only
									</MenuItem>
									<MenuItem value="all">Show All</MenuItem>
								</Select>
							</FormControl>
						</>
					}
					exportEnabled={true}
					fixedHeader={true}
					actionItems={[
						{
							name: 'Edit',
							onClick: (e, row) => onRowClicked(row),
						},
						{
							name: 'Delete',
							onClick: (e, row) => showRemoveModal(row),
							hideIf: (row) => row.deletedAt !== null,
						},
						{
							name: 'Restore',
							onClick: (e, row) => showRestoreModal(row),
							hideIf: (row) => row.deletedAt === null,
						},
					]}
					addNewBtn={{ text: 'Add Branch', onClick: onAddClick }}
				/>
			</div>
		</Page>
	)
}
