import axios from 'axios'
import { state as ventiState } from 'venti'
import PublishSubscribe from 'publish-subscribe-js'
import { getTheme } from '../config'

const theme = getTheme()

export const host =
	process.env.NODE_ENV === 'development'
		? theme.api.host.dev
		: theme.api.host.production

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.status === 401) {
			// @todo there should not be a global logout handler on a 401. requests should be handled individually
			PublishSubscribe.publish('LOG_OUT_USER')
		}

		return Promise.reject(error)
	}
)

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function get(url, config = {}) {
	return axios
		.get(url, config)
		.then(
			(response) => typeof response !== 'undefined' && response.data
		)
		.catch((error) => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function post(url, data, config = {}) {
	return axios
		.post(url, data, config)
		.then(
			(response) => typeof response !== 'undefined' && response.data
		)
		.catch((error) => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function put(url, data, config = {}) {
	return axios
		.put(url, data, config)
		.then(
			(response) => typeof response !== 'undefined' && response.data
		)
		.catch((error) => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function patch(url, data, config = {}) {
	return axios
		.patch(url, data, config)
		.then(
			(response) => typeof response !== 'undefined' && response.data
		)
		.catch((error) => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function remove(url, config = {}) {
	return axios
		.delete(url, config)
		.then((response) => response?.data)
		.catch((error) => Promise.reject(error.response.data))
}

/**
 * @param {string} userName
 * @param {string} password
 * @param {string} id
 * @returns {Promise}
 */
export function authenticate(userName, password, id) {
	return post(`${host}/token`, {
		userName,
		password,
		siteConfigurationId: id,
	})
}

/**
 * @param {Object} data
 * @returns {Promise}
 */
export function authenticateWithChallengeCode(data) {
	return post(`${host}/token/code`, data)
}

/**
 * Create new user account
 * @param {{branchId: unknown, firstName: string, lastName: string, password: string, phone: string, siteConfigurationId, sendNotification: boolean, title: string, email: string}} data
 * @param {string} data.siteConfigurationId
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.phone
 * @param {string} data.firstName
 * @param {string} data.lastName
 * @param {string} data.title
 * @param {string} data.inviteToken
 * @returns {Promise}
 */
export function registerBorrower(data) {
	return post(`${host}/api/user/register`, data)
}

/** Resend registration code
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.siteConfigurationId
 * @returns {Promise}
 */
export function resendRegistrationCode(data) {
	return post(`${host}/api/user/resendregistrationcode`, data)
}

/**
 * @deprecated
 * @param {object} data
 * @param {string} password
 * @returns {Promise}
 */
export function registerAccount(data, password) {
	const body = {
		Email: data.email,
		Password: password,
		ConfirmPassword: password,
	}
	return post(`${host}/api/customer/account/RegisterAccount`, body)
}

/**
 * @param email
 * @param siteConfigId
 */
export function sendForgotPasswordEmail(email, siteConfigId) {
	return post(`${host}/api/user/forgotpassword`, {
		email,
		siteConfigurationId: siteConfigId,
	})
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function registerLoanOfficer({
	AccountID = '',
	SiteConfigurationID = '',
	Email = '',
	Password = '',
	Phone = '',
	FirstName = '',
	LastName = '',
}) {
	return post(`${host}/api/users/registerLoanOfficer`, {
		AccountID,
		SiteConfigurationID,
		Email,
		Password,
		ConfirmPassword: Password,
		Phone,
		FirstName,
		LastName,
	})
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function registerUser(data) {
	return post(`${host}/api/user/register`, data)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createUser(data) {
	return post(`${host}/api/user`, data)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateUser(data) {
	return put(`${host}/api/user/${data.id}`, data)
}

/**
 * Get authenticated user data
 * @returns {Promise}
 */
export function getMe() {
	return get(`${host}/api/user/me`)
}

export function getMyRelationships() {
	return get(`${host}/api/user/me/relationships`)
}

/**
 * Get logged in user's relationship prospects
 * @returns {Promise}
 */
export function getMyRelationshipProspects() {
	return get(`${host}/api/user/me/relationships/prospects`)
}

/**
 * Delete relationship prospect
 * @param {string} id
 * @returns {Promise}
 */
export function deleteRelationshipProspect(id) {
	return remove(`${host}/api/user/me/relationships/prospects/${id}`)
}

/**
 * Update authenticated user data
 * @param data {Object}
 * @returns {Promise}
 */
export function updateMe(data) {
	return put(`${host}/api/user/me`, data)
}

/**
 * Update my phone
 * @param phone {string}
 * @returns {Promise}
 */
export function updateMyPhone(phone) {
	return put(`${host}/api/user/me/phone-number`, { phone })
}

/**
 * Update authenticated user data
 * @param data {Object}
 * @returns {Promise}
 */
export function verifyMobilePhone(data) {
	return put(`${host}/api/user/mobile-phone/verify-code`, data)
}

/**
 * Send mobile phone auth code
 * @returns {Promise}
 */
export function sendMobilePhoneAuthCode() {
	return post(`${host}/api/user/mobile-phone/send-code`, null)
}

/**
 * Send E-Consent
 * @param loanId
 * @param siteConfigurationID
 * @returns {Promise}
 */
export function sendEConsent({
	loanId = '',
	siteConfigurationId = '',
}) {
	return post(`${host}/api/los/loan/econsent`, {
		loanId,
		siteConfigurationId,
	})
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function deleteUser(id) {
	return remove(`${host}/api/user/${id}`)
}

/**
 * Delete me user
 * @param {string} feedback
 * @returns {Promise}
 */
export function deleteMe(feedback) {
	return post(`${host}/api/user/me/delete`, feedback)
}

/**
 * @param {string} oldPassword
 * @param {string} newPassword

 * @returns {Promise}
 */
export function changePassword(oldPassword, newPassword) {
	return post(`${host}/api/user/changepassword`, {
		oldPassword,
		newPassword,
	})
}

/**
 * Get customer data
 * @param {string} id
 * @returns {Promise}
 */
export function getCustomer(id) {
	return get(`${host}/api/customers/${id}`)
}

export function getAllDocumentBuckets() {
	return get(`${host}/api/los/system/documentbuckets`)
}

/**
 * Get users
 * @param {boolean} includeDeleted
 * @returns {Promise}
 */
export function getUsers(includeDeleted = false) {
	return get(
		`${host}/api/user${includeDeleted ? '?showAll=true' : ''}`
	)
}

export function searchUsers(searchCriteria, params = null) {
	return post(
		`${host}/api/users/search`,
		searchCriteria,
		params ? { params } : {}
	)
}

/**
 * Get user relations
 * @returns {Promise}
 */
export function getUserRelations(id) {
	return get(`${host}/api/user/${id}/relation`)
}

/**
 * Create user relation
 * @returns {Promise}
 */
export function createUserRelation(userId1, userId2, relationType) {
	return post(`${host}/api/user/relation`, {
		userId1,
		userId2,
		relationType,
	})
}

/**
 * Create user relation
 * @returns {Promise}
 */
export function removeUserRelation(id) {
	return remove(`${host}/api/user/relation/${id}`)
}

/**
 * Undelete user
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteUser(id) {
	return post(`${host}/api/user/${id}/undelete`, null)
}

/**
 * @param {string} loanId
 * @returns {Promise}
 */
export function getLoanUsers(loanId) {
	return get(`${host}/api/loans/${loanId}/users`)
}

/**
 * Save loan draft
 * @param {object} data
 * @returns {Promise}
 */
export function saveLoanDraft(data) {
	if (data.applicationPayload.draftId) {
		return put(
			`${host}/api/los/loan/drafts/${data.applicationPayload.draftId}`,
			data
		)
	}

	return post(`${host}/api/los/loan/drafts`, data)
}

/**
 * Get loan drafts
 * @returns {Promise}
 */
export function getLoanDrafts() {
	return get(`${host}/api/los/loan/drafts`)
}

/**
 * Get loan draft
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanDraft(id) {
	return get(`${host}/api/los/loan/drafts/${id}`)
}

/**
 * Delete loan draft (permanent)
 * @param id
 * @returns {Promise}
 */
export function deleteLoanDraft(id) {
	return remove(`${host}/api/los/loan/drafts/${id}`)
}

/**
 * Get loan data
 * @param {string} id
 * @returns {Promise}
 */
export function getLoan(id) {
	return get(`${host}/api/los/loan/application/${id}`)
}

/**
 * Run report
 * @returns {Promise}
 */
export function getReport(data) {
	return post(`${host}/api/los/loan/reports`, data)
}

/**
 * Run LO Loan Calculation
 * @param {Object} data
 * @returns {Promise}
 */
export function runLOCalculation(data) {
	return post(`${host}/api/los/loan/localculation`, data)
}

/**
 * Get LO Loan Calculation
 * @param {string} id
 * @returns {Promise}
 */
export function getLOCalculation(id) {
	return get(`${host}/api/los/loan/localculation/${id}`)
}

export function getLoanComparisons(loanID) {
	return get(`${host}/api/loans/${loanID}/loan-comparison`)
}

export function saveLoanComparison(loanID, index, data) {
	return post(
		`${host}/api/loans/${loanID}/loan-comparison/${index}`,
		data
	)
}

export function saveLoanComparisonPdf(loanID, data) {
	return post(`${host}/api/loans/${loanID}/loan-comparison/pdf`, data)
}

export function deleteLoanComparison(loanID, index) {
	return remove(
		`${host}/api/loans/${loanID}/loan-comparison/${index}`
	)
}

/**
 * Get loan preliminary conditions
 * @param {string} loanID
 * @returns {Promise}
 */
export function getLoanPreliminaryConditions(loanID) {
	return get(`${host}/api/los/loan/${loanID}/conditions/preliminary`)
}

/**
 * Get loan underwriting conditions
 * @param {string} loanID
 * @returns {Promise}
 */
export function getLoanUnderwritingConditions(loanID) {
	return get(`${host}/api/los/loan/${loanID}/conditions/underwriting`)
}

/**
 * Create task
 * @param {object} data
 * @returns {Promise}
 */
export function createTask(data) {
	return post(`${host}/api/tasks`, data)
}

/**
 * Create task
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function updateTask(id, data) {
	return put(`${host}/api/tasks/${id}`, data)
}

/**
 * Search tasks
 * @param {object} data
 * @param {object} params
 * @returns {Promise}
 */
export function searchTasks(data, params = null) {
	return post(
		`${host}/api/tasks/search`,
		data,
		params ? { params } : null
	)
}

/**
 * Get loan tasks
 * @param {string} loanId
 * @returns {Promise}
 */
export function getLoanTasks(loanId) {
	return get(`${host}/api/loans/${loanId}/tasks`)
}

/**
 * Get loan tasks diff
 * @param {string} loanId
 * @returns {Promise}
 */
export function getLoanTasksDifference(loanId) {
	return get(`${host}/api/loans/${loanId}/tasks/diff`)
}

/**
 * Send outstanding loan task notification
 * @param {string} loanId
 * @returns {Promise}
 */
export function sendOutstandingLoanTaskNotification(loanId) {
	return post(
		`${host}/api/loans/${loanId}/tasks/reminders/outstanding`,
		null
	)
}

/**
 * Add loan task
 * @param {string} loanId
 * @param {string} taskId
 * @param {object} data
 * @returns {Promise}
 */
export function createLoanTask(loanId, taskId, data) {
	return post(`${host}/api/loans/${loanId}/tasks/${taskId}`, data)
}

/**
 * Update loan task
 * @param {string} loanId
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function updateLoanTask(loanId, id, data) {
	return put(`${host}/api/loans/${loanId}/tasks/${id}`, data)
}

/**
 * Delete loan task
 * @param {string} loanId
 * @param {string} id
 * @returns {Promise}
 */
export function deleteLoanTask(loanId, id) {
	return remove(`${host}/api/loans/${loanId}/tasks/${id}`)
}

/**
 * Create loan task document
 * @param {string} loanId
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function createLoanTaskDocument(loanId, id, data) {
	const { name, file } = data
	const formData = new FormData()
	formData.append('name', name || file.name)
	formData.append('file', file)
	return post(
		`${host}/api/loans/${loanId}/tasks/${id}/documents`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	)
}

/**
 * Get rules
 * @returns {Promise}
 */
export function getBusinessRules(includeDeleted = false) {
	return get(
		`${host}/api/business-rules${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * Get rule by id
 * @returns {Promise}
 */
export function getBusinessRule(id) {
	return get(`${host}/api/business-rules/${id}`)
}

/**
 * Create rule
 * @param {object} data
 * @returns {Promise}
 */
export function createBusinessRule(data) {
	return post(`${host}/api/business-rules`, data)
}

/**
 * Update rule
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function updateBusinessRule(id, data) {
	return put(`${host}/api/business-rules/${id}`, data)
}

/**
 * Delete rule
 * @param {string} id
 * @returns {Promise}
 */
export function removeBusinessRule(id) {
	return remove(`${host}/api/business-rules/${id}`)
}

/**
 * Undelete rule
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteBusinessRule(id) {
	return post(`${host}/api/business-rules/${id}/undelete`, null)
}

/**
 * Get forms by Site Config
 * @param {string} siteConfigurationId
 * @param {number=} formType
 * @param {string=} slug
 */
export function getFormsBySiteConfig({
	siteConfigurationId,
	formType,
	slug,
}) {
	return post(`${host}/api/siteForm`, {
		siteConfigurationId,
		formType,
		slug,
	})
}

/**
 * Create a new loan application
 * @param {object} data
 * @param {string} accessToken
 * @returns {Promise}
 */
export function createLoanApplication(data, accessToken = '') {
	return post(
		`${host}/api/los/loan/application`,
		data,
		accessToken ? { headers: { Authorization: accessToken } } : null
	)
}

/**
 * Update a loan application
 * @param id
 * @param data
 * @returns {Promise}
 */
export function updateLoanApplication(id, data) {
	return patch(`${host}/api/los/loan/application/${id}`, data)
}

/**
 * Add a document to a loan task
 * @param {object} data
 * @returns {Promise}
 */
export function addLoanTaskDocument(data) {
	return post(`${host}/api/los/loan/tasks/documents`, data)
}

/**
 * Get loan documents associated with a loan
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanDocuments(id) {
	return get(
		`${host}/api/los/loan/tasks/documents/${id}?includeBase64=false`
	)
}

/**
 * Get loan documents associated with a loan
 * @param {string} loanId
 * @param {string} documentId
 * @param {boolean} preview
 * @returns {Promise}
 */
export function getLoanDocument(loanId, documentId, preview = false) {
	return get(
		`${host}/api/loans/${loanId}/documents/${documentId}`,
		preview ? { params: { preview } } : null
	)
}

/**
 * Get loan document url
 * @param {string} loanId
 * @param {string} documentId
 * @returns {Promise}
 */
export function getLoanDocumentUrl(loanId, documentId) {
	return `${host}/api/loans/${loanId}/documents/${documentId}/download?&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

/**
 * Get loan documents associated with a loan
 * @param {string} loanId
 * @param {object} data
 * @returns {Promise}
 */
export function createLoanDocument(loanId, data) {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}
	const formData = new FormData()
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key])
	})
	return post(
		`${host}/api/loans/${loanId}/documents`,
		formData,
		config
	)
}

export function getLoanDocumentContentUrl({ loanId, documentId }) {
	return `${host}/api/los/loan/${loanId}/document/${documentId}/content?contentType=binary&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

export function getLoanDocumentContent({ loanId, documentId }) {
	return get(
		`${host}/api/los/loan/${loanId}/document/${documentId}/content?contentType=base64`
	)
}

/**
 * Get Loan Document Buckets
 * @param {string} loanId
 * @param {object} params
 * @returns {Promise}
 */
export function getLoanDocumentBuckets(loanId, params = null) {
	return get(
		`${host}/api/loans/${loanId}/documents/buckets`,
		params ? { params } : null
	)
}

/**
 * Get Document Buckets
 * @param {object} params
 * @returns {Promise}
 */
export function getDocumentBuckets(params = null) {
	return get(
		`${host}/api/document-buckets`,
		params ? { params } : null
	)
}

/**
 * Add a condition to a loan
 * @param {string} type
 * @param {object} data
 * @returns {Promise}
 */
export function addLoanCondition(type, data) {
	return post(`${host}/api/los/loan/condition/${type}`, data)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function getLoanPricing(data) {
	return post(`${host}/api/los/pricing/calculator`, data)
}

/**
 * Get disclosure package information
 * @param {string} loanId
 * @param {string} email
 * @returns {Promise}
 */
export function getDisclosurePackageInfo(loanId, email) {
	return post(`${host}/api/los/loan/disclosureinfo`, {
		BorrowerEmail: email,
		LoanID: loanId,
	})
}

/**
 * @returns {Promise}
 */
export function getCustomerSiteConfigurations() {
	return get(`${host}/api/customer/account/siteconfiguration`)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateSiteConfiguration(data) {
	return put(`${host}/api/customer/account/siteconfiguration`, data)
}

/**
 * @returns {Promise}
 */
export function getCorporations(includeDeleted = false) {
	return get(
		`${host}/api/customer/corporate${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function deleteCorporate(id) {
	return remove(`${host}/api/customer/corporate/${id}`)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteCorporate(id) {
	return post(`${host}/api/customer/corporate/${id}/undelete`, null)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createCorporation(data) {
	return post(`${host}/api/customer/corporate`, data)
}

/**
 * @param {object} data
 * @param {string} corporateId
 * @returns {Promise}
 */
export function createCorporationSiteConfiguration(
	data,
	corporateId
) {
	return post(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration`,
		data
	)
}

/**
 * @param {object} data
 * @param {string} corporateId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateCorporationSiteConfiguration(
	data,
	corporateId,
	siteConfigurationId
) {
	return put(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration/${siteConfigurationId}${data.updateDownstream ? '?applyToChildren=true' : ''}`,
		data
	)
}

/**
 * @param {string} corporateId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getCorporationSiteConfiguration(
	corporateId,
	siteConfigurationId
) {
	return get(
		`${host}/api/customer/corporate/${corporateId}/siteconfiguration/${siteConfigurationId}`
	)
}

/**
 * @returns {Promise}
 */
export function getLoanOfficers(includeDeleted = false) {
	return get(
		`${host}/api/customer/loanofficer${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getLoanOfficer(id) {
	return get(`${host}/api/customer/loanofficer/${id}`)
}

/**
 * @param {string} loanOfficerId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getLoanOfficerSiteConfiguration(
	loanOfficerId,
	siteConfigurationId
) {
	return get(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration/${siteConfigurationId}`
	)
}

/**
 * @param {object} data
 * @param {string} loanOfficerId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateLoanOfficerSiteConfiguration(
	data,
	loanOfficerId,
	siteConfigurationId
) {
	return put(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration/${siteConfigurationId}`,
		data
	)
}

/**
 * @param {object} data
 * @param {string} loanOfficerId
 * @returns {Promise}
 */
export function createLoanOfficerSiteConfiguration(
	data,
	loanOfficerId
) {
	return post(
		`${host}/api/customer/loanofficer/${loanOfficerId}/siteconfiguration`,
		data
	)
}

/**
 * Get forms
 * @returns {Promise}
 */
export function getForms(includeDeleted = false) {
	return get(
		`${host}/api/form${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * Get form
 * @param {string} id
 * @returns {Promise}
 */
export function getForm(id) {
	return get(`${host}/api/form/${id}`)
}

/**
 * Create or update form
 * @param {object} data
 * @returns {Promise}
 */
export function saveForm(data) {
	return !data.id
		? post(`${host}/api/form`, { ...data, createdAt: new Date() })
		: put(`${host}/api/form/${data.id}`, data)
}

/**
 * Delete form
 * @param {string} id
 * @returns {Promise}
 */
export function deleteForm(id) {
	return remove(`${host}/api/form/${id}`)
}

/**
 * Undelete form
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteForm(id) {
	return post(`${host}/api/form/${id}/undelete`, null)
}

/**
 * Get form versions
 * @param {string} formId
 * @returns {Promise}
 */
export function getFormVersions(formId) {
	return get(`${host}/api/form/${formId}/versions`)
}

/**
 * Get form version
 * @param {string} formId
 * @param {string} versionId
 * @returns {Promise}
 */
export function getFormVersion({ formId, versionId }) {
	return get(`${host}/api/form/${formId}/versions/${versionId}`)
}

/**
 * Create or update form version
 * @param {string} formId
 * @param {object} data
 * @returns {Promise}
 */
export function saveFormVersion({ formId, data }) {
	return !data.id
		? post(`${host}/api/form/${formId}/versions`, {
				...data,
				createdAt: new Date(),
			})
		: put(`${host}/api/form/${formId}/versions/${data.id}`, data)
}

/**
 * Delete form version
 * @param {string} formId
 * @param {string} versionId
 * @returns {Promise}
 */
export function deleteFormVersion({ formId, versionId }) {
	return remove(`${host}/api/form/${formId}/versions/${versionId}`)
}

/**
 * Get branches
 * @returns {Promise}
 */
export function getBranches(includeDeleted = false) {
	return get(
		`${host}/api/customer/branch${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * Delete branch
 * @param {string} id
 * @returns {Promise}
 */
export function deleteBranch(id) {
	return remove(`${host}/api/customer/branch/${id}`)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteBranch(id) {
	return post(`${host}/api/customer/branch/${id}/undelete`, null)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getBranch(id) {
	return get(`${host}/api/customer/branch/${id}`)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createBranch(data) {
	return post(`${host}/api/customer/branch`, data)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function updateBranch(data) {
	return put(`${host}/api/customer/branch/${data.id}`, data)
}

/**
 * @param {object} data
 * @param {string} branchId
 * @returns {Promise}
 */
export function createBranchSiteConfiguration(data, branchId) {
	return post(
		`${host}/api/customer/branch/${branchId}/siteconfiguration`,
		data
	)
}

/**
 * @param {object} data
 * @param {string} branchId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updateBranchSiteConfiguration(
	data,
	branchId,
	siteConfigurationId
) {
	return put(
		`${host}/api/customer/branch/${branchId}/siteconfiguration/${siteConfigurationId}${data.updateDownstream ? '?applyToChildren=true' : ''}`,
		data
	)
}

/**
 * @param {string} branchId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getBranchSiteConfiguration(
	branchId,
	siteConfigurationId
) {
	return get(
		`${host}/api/customer/branch/${branchId}/siteconfiguration/${siteConfigurationId}`
	)
}

/**
 * @returns {Promise}
 */
export function getPartners(
	role = 'realtor',
	includeDeleted = false
) {
	return get(
		`${host}/api/customer/${role}${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getPartner(id) {
	return get(`${host}/api/customer/realtor/${id}`)
}

/**
 * @param {string} realtorId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function getPartnerSiteconfiguration(
	realtorId,
	siteConfigurationId
) {
	return get(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration/${siteConfigurationId}`
	)
}

/**
 * @param {object} data
 * @param {string} realtorId
 * @param {string} siteConfigurationId
 * @returns {Promise}
 */
export function updatePartnerSiteconfiguration(
	data,
	realtorId,
	siteConfigurationId
) {
	return put(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration/${siteConfigurationId}`,
		data
	)
}

/**
 * @param {object} data
 * @param {string} realtorId
 * @returns {Promise}
 */
export function createPartnerSiteconfiguration(data, realtorId) {
	return post(
		`${host}/api/customer/realtor/${realtorId}/siteconfiguration`,
		data
	)
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function deletePartner(id) {
	return remove(`${host}/api/user/${id}`)
}

export function getLoanApplications() {
	return get(`${host}/api/loans`)
}

export function getCorporateLoanOfficers(id, sendToken = false) {
	return get(`${host}/api/customer/corporate/${id}/loanofficers`, {
		headers: sendToken === false ? { Authorization: null } : {},
	})
}

export function getCorporateBranches(id, sendToken = false) {
	return get(`${host}/api/customer/corporate/${id}/branches`, {
		headers: sendToken === false ? { Authorization: null } : {},
	})
}

export function getBranchLoanOfficers(id) {
	return get(`${host}/api/customer/branch/${id}/loanofficers`)
}

/**
 * @returns {Promise}
 */
export function getSurveys() {
	return get(`${host}/api/survey`)
}

/**
 * @param {String} email
 * @returns {Promise}
 */
export function getSurveysByEmail(email) {
	return post(`${host}/api/survey`, {
		loanOfficerEmailAddress: email,
	})
}

/**
 * @returns {Promise}
 */
export function getRequestsQueue() {
	return get(`${host}/api/requestqueue`)
}

/**
 * Delete request from queue
 * @param {string} id
 * @returns {Promise}
 */
export function deleteRequestQueue(id) {
	return remove(`${host}/api/requestqueue/${id}`)
}

/**
 * @param {uuid} id
 * @param {boolean} force
 * @returns {Promise}
 */
export function runQueuedTask(id, force = true) {
	return post(
		`${host}/api/requestqueue/run/${id}?force=${force ? 'true' : 'false'}`,
		null
	)
}

/**
 * Get Account
 * @returns {Promise}
 */
export function getAccount() {
	return get(`${host}/api/account`)
}

/**
 * Update Account
 * @param {object} data
 * @returns {Promise}
 */
export function updateAccount(data) {
	return put(`${host}/api/account`, data)
}

/**
 * @param {object} data
 * @returns {Promise}
 */
export function createSiteConfiguration(data) {
	return post(`${host}/api/customer/siteconfiguration`, data)
}

/**
 * Get site configuration by url
 * @param {string} path
 * @returns {Promise}
 */
export function getSiteConfigurationByUrl(path) {
	let url = path
	if (url === 'localhost' || url.includes('ngrok-free'))
		url = theme.api._localhostAlias
	return post(`${host}/api/customer/siteconfiguration/url`, { url })
}

/**
 * Send borrower invitation
 * @param {object} data
 * @returns {Promise}
 */
export function sendBorrowerInvitation(data) {
	return post(`${host}/api/users/invites`, data)
}

/**
 * @param token
 * @returns {Promise}
 */
export function verifyBorrowerInvitation(token) {
	return get(`${host}/api/users/invites/${token}/verify`)
}

/**
 * Forgot Password
 * @param data
 * @returns {Promise}
 */
export function verifyUserResetPassword(data) {
	return post(`${host}/api/user/verifypassword`, data)
}

/**
 * @param data
 * @returns {Promise}
 */
export function verify(data) {
	return post(`${host}/api/verification/verify`, data)
}

export function getEmbeddedSigningUrl({
	envelopeId,
	firstName,
	lastName,
	email,
}) {
	return post(
		`${host}/api/los/loan/embeddedsigning/${envelopeId}/${firstName}%20${lastName}/${email}`,
		{
			envelopeID: envelopeId,
			userName: `${firstName}%20${lastName}`,
			email,
		}
	)
}

/**
 * @param {string} id
 * @param {string} password
 * @returns {Promise}
 */
export function updateUserPassword(id, password) {
	return post(`${host}/api/user/${id}/overridepassword`, { password })
}

/**
 * Get notification templates
 * @returns {Promise}
 */
export function getNotificationTemplates() {
	return get(`${host}/api/notification/template`)
}

/**
 * Get notification template
 * @param {string} id
 * @returns {Promise}
 */
export function getNotificationTemplate(id = '') {
	return get(`${host}/api/notification/template/${id}`)
}

/**
 * Save notification template
 * @param {object} data
 * @returns {Promise}
 */
export function saveNotificationTemplate(data) {
	return !data.id
		? post(`${host}/api/notification/template`, data)
		: put(`${host}/api/notification/template/${data.id}`, data)
}

/**
 * Delete notification template
 * @param {string} id
 * @returns {Promise}
 */
export function deleteNotificationTemplate(id) {
	return remove(`${host}/api/notification/template/${id}`)
}

/**
 * Undelete notification template
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteNotificationTemplate(id) {
	return post(
		`${host}/api/notification/template/${id}/undelete`,
		null
	)
}

/**
 * Send test notification
 * @param {object} data
 * @returns {Promise}
 */
export function sendTestNotification(data) {
	return post(`${host}/api/notification/test`, data)
}

/**
 * Get notification template versions
 * @param {string} templateId
 * @returns {Promise}
 */
export function getNotificationTemplateVersions({ templateId }) {
	return get(
		`${host}/api/notification/template/${templateId}/versions`
	)
}

/**
 * Get notification template version
 * @param {string} documentId
 * @param {string} versionId
 * @returns {Promise}
 */
export function getNotificationTemplateVersion({
	templateId,
	versionId,
}) {
	return get(
		`${host}/api/notification/template/${templateId}/versions/${versionId}`
	)
}

/**
 * Save notification template version
 * @param {string} documentId
 * @param {object} data
 * @returns {Promise}
 */
export function saveNotificationTemplateVersion({
	templateId,
	data,
}) {
	return !data.id
		? post(
				`${host}/api/notification/template/${templateId}/versions`,
				data
			)
		: put(
				`${host}/api/document/template/${templateId}/versions/${data.id}`,
				data
			)
}

/**
 * Delete notification template version
 * @param {string} documentId
 * @param {string} versionId
 * @returns {Promise}
 */
export function deleteNotificationTemplateVersion({
	templateId,
	versionId,
}) {
	return remove(
		`${host}/api/notification/template/${templateId}/versions/${versionId}`
	)
}

/**
 * @returns {Promise}
 */
export function getDocumentTemplates(includeDeleted = false) {
	return get(
		`${host}/api/document/template${includeDeleted ? '?showAll=true' : ''}`
	)
}

/**
 * Get document templates by type
 * @param {string} type
 * @returns {Promise}
 */
export function getDocumentTemplatesByType(type) {
	return get(`${host}/api/document/template/${type}`)
}

/**
 * Get document template
 * @param {string} id
 * @returns {Promise}
 */
export function getDocumentTemplate(id = '') {
	return get(`${host}/api/document/template/${id}`)
}

/**
 * Save document template
 * @param {object} data
 * @returns {Promise}
 */
export function saveDocumentTemplate(data) {
	return !data.id
		? post(`${host}/api/document/template`, data)
		: put(`${host}/api/document/template/${data.id}`, data)
}

/**
 * Delete document template
 * @param {string} id
 * @returns {Promise}
 */
export function deleteDocumentTemplate(id) {
	return remove(`${host}/api/document/template/${id}`)
}

/**
 * Undelete document template
 * @param {string} id
 * @returns {Promise}
 */
export function undeleteDocumentTemplate(id) {
	return post(`${host}/api/document/template/${id}/undelete`, null)
}

/**
 * Get document template versions
 * @param {string} documentId
 * @returns {Promise}
 */
export function getDocumentTemplateVersions({ documentId }) {
	return get(`${host}/api/document/template/${documentId}/versions`)
}

/**
 * Get document template version
 * @param {string} documentId
 * @param {string} versionId
 * @returns {Promise}
 */
export function getDocumentTemplateVersion({
	documentId,
	versionId,
}) {
	return get(
		`${host}/api/document/template/${documentId}/versions/${versionId}`
	)
}

/**
 * Save document template version
 * @param {string} documentId
 * @param {object} data
 * @returns {Promise}
 */
export function saveDocumentTemplateVersion({ documentId, data }) {
	return !data.id
		? post(
				`${host}/api/document/template/${documentId}/versions`,
				data
			)
		: put(
				`${host}/api/document/template/${documentId}/versions/${data.id}`,
				data
			)
}

/**
 * Delete document template version
 * @param {string} documentId
 * @param {string} versionId
 * @returns {Promise}
 */
export function deleteDocumentTemplateVersion({
	documentId,
	versionId,
}) {
	return remove(
		`${host}/api/document/template/${documentId}/versions/${versionId}`
	)
}

/**
 * Generate document from template
 * @param {object} data
 * @returns {Promise}
 */
export function generateDocumentFromTemplate(data) {
	return post(`${host}/api/los/loan/generateDocument`, data)
}

/**
 * Get milestone configurations
 * @returns {Promise}
 */
export function getMilestoneConfigurations() {
	return get(`${host}/api/milestones`)
}

/**
 * Create milestone configurations
 * @param {object} data
 * @returns {Promise}
 */
export function createMilestoneConfiguration(data) {
	return post(`${host}/api/milestones`, data)
}

/**
 * Update milestone configurations
 * @param {string }id
 * @param {object} data
 * @returns {Promise}
 */
export function updateMilestoneConfiguration(id, data) {
	return put(`${host}/api/milestones/${id}`, data)
}

/**
 * Delete milestone configuration
 * @param {string} id
 * @returns {Promise}
 */
export function deleteMilestoneConfiguration(id) {
	return remove(`${host}/api/milestones/${id}`)
}

/**
 * Search form submissions
 * @param {object} criteria
 * @param {object} params
 * @returns {Promise}
 */
export function searchFormSubmissions(criteria, params = null) {
	return post(
		`${host}/api/form-submissions/search`,
		criteria,
		params ? { params } : {}
	)
}

/**
 * Get form submission
 * @param {string} id
 * @returns {Promise}
 */
export function getFormSubmission(id) {
	return get(`${host}/api/form-submissions/${id}`)
}

/**
 * Create form submission
 * @param {object} data
 * @returns {Promise}
 */
export function createFormSubmission(data) {
	return post(`${host}/api/form-submissions`, data)
}

/**
 * Update form submission
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function updateFormSubmission(id, data) {
	return put(`${host}/api/form-submissions/${id}`, data)
}

/**
 * Delete form submission
 * @param {string} id
 * @returns {Promise}
 */
export function deleteFormSubmission(id) {
	return remove(`${host}/api/form-submissions/${id}`)
}

/**
 * Get devices
 * @param {string} accountId
 * @returns {Promise}
 */
export function getDevices(accountId) {
	return get(`${host}/api/devices?posAccountId=${accountId}`)
}

/**
 * Get device
 * @param {string} id
 * @returns {Promise}
 */
export function getDevice(id) {
	return get(`${host}/api/devices/${id}`)
}

/**
 * Update device
 * @param {object} data
 * @returns {Promise}
 */
export function updateDevice(data) {
	return patch(`${host}/api/devices/${data.id}`, data)
}

/**
 * Get device by serial number
 * @param sn
 * @returns {Promise}
 */
export function getDeviceBySerialNumber(sn) {
	return get(`${host}/api/devices/${sn}/profile`)
}

/**
 * Send device actions by serial number
 * @param {string} sn
 * @param {string} action
 * @returns {Promise}
 */
export function sendDeviceActionBySerialNumber({ sn, action }) {
	return post(`${host}/api/devices/${sn}/actions/${action}`, null)
}

export function getListings() {
	return get(`${host}/api/listings`)
}

export function searchListings(criteria, params = null) {
	return post(
		`${host}/api/listings/search`,
		criteria,
		params ? { params } : {}
	)
}

export function getListing(listingId) {
	return get(`${host}/api/listings/${listingId}`)
}

export function getListingBySlug(slug) {
	return get(`${host}/api/listings/slug/${slug}`)
}

export function getListingOpenHouseFlyerUrl(id) {
	return `${host}/api/listings/${id}/open-house-flyer`
}

export function createListing(data) {
	return post(`${host}/api/listings`, data)
}

export function updateListing(listingId, data) {
	return put(`${host}/api/listings/${listingId}`, data)
}

export function deleteListing(listingId) {
	return remove(`${host}/api/listings/${listingId}`)
}

export function updateListingBackgroundImage(listingId, file) {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}

	const formData = new FormData()
	formData.append('file', file)
	return put(
		`${host}/api/listings/${listingId}/background-image`,
		formData,
		config
	)
}

export function addListingImage(listingId, image) {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}
	const formData = new FormData()
	formData.append('file', image)
	return post(
		`${host}/api/listings/photos/${listingId}`,
		formData,
		config
	)
}

export function deleteListingImage(listingId, id) {
	return remove(`${host}/api/listings/photos/${listingId}/${id}`)
}

export function addListingFile(listingId, file) {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}
	const formData = new FormData()
	formData.append('file', file)
	return post(
		`${host}/api/listings/files/${listingId}`,
		formData,
		config
	)
}

export function addFormSubmissionFile(formSubmissionId, file) {
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}
	const formData = new FormData()
	formData.append('file', file)
	formData.append('name', file.name)
	return post(
		`${host}/api/form-submissions/${formSubmissionId}/files`,
		formData,
		config
	)
}

export function deleteListingFile(listingId, id) {
	return remove(`${host}/api/listings/files/${listingId}/${id}`)
}

export function deleteFormSubmissionFile(
	formSubmissionId,
	formSubmissionFileId
) {
	return remove(
		`${host}/api/form-submissions/${formSubmissionId}/files/${formSubmissionFileId}`
	)
}
export function requestImpersonation(email) {
	return post(`${host}/api/users/impersonation/request`, { email })
}

export function allowImpersonation(email) {
	return post(`${host}/api/users/impersonation/allow`, {
		email,
		expiration: '01:00:00',
	})
}

export function startImpersonation() {
	return post(`${host}/api/users/impersonation`, null)
}

export function stopImpersonation() {
	return remove(`${host}/api/users/impersonation`, null)
}

export function allowImpersonationWithToken(token) {
	return post(`${host}/api/users/impersonation/allow/${token}`, null)
}

export function extendImpersonation() {
	return post(`${host}/api/users/impersonation/extend`, null)
}

/**
 * Roles
 * @type Object
 */
export const Roles = {
	borrower: 'Borrower', // 0
	loanOfficer: 'LoanOfficer', // 1
	admin: 'Admin', // 2
	// superAdmin: 'SuperAdmin', // 3
	realtor: 'Realtor', // 4
	settlementAgent: 'SettlementAgent',
	branchManager: 'BranchManager', // 17
	loanProcessor: 'LoanProcessor', // 6
	loanOfficerAssistant: 'LoanOfficerAssistant', // 7
}

/**
 * Site types
 * @type {{realtor: number, corporate: number, admin: number, branch: number, loanOfficer: number}}
 */
export const SiteTypes = {
	admin: 0,
	corporate: 1,
	branch: 2,
	loanOfficer: 3,
	realtor: 4,
}
